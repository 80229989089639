import axios from 'axios';
import { authHeader } from './auth-header';

export const courierService = {
    getRecentTrackingLogs,
    getAllDropdown,
    getAll,
    create,
    update,
    destroy,
    addTracking
};

function getRecentTrackingLogs({ 
    limit 
}) {
    return axios.get('/delivery/add/tracking/get-activity-logs', {
        params: {
            limit,
        },
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function getAllDropdown() {
    return axios.get('/tracking_courier', {
        params: {
            pageSize: 100
        },
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function getAll({ 
    pageSize, 
    page, 
    search, 
    orderBy, 
    sortColumn  
}) {
    return axios.get('/tracking_courier', {
        params: {
            pageSize,
            page,
            search,
            orderBy,
            sortColumn
        },
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function create(data) {
    return axios.post('/tracking_courier', data, {
        headers: { ...authHeader(), 'Content-Type': 'multipart/form-data' }
    })
    .then(({ data }) => {
        return data;
    })
}

function update(data, id) {
    return axios.post(`/tracking_courier/${id}`, data, {
        headers: { ...authHeader(), 'Content-Type': 'multipart/form-data' }
    })
    .then(({ data }) => {
        return data;
    })
}

function destroy(id) {
    return axios.delete(`/tracking_courier/${id}`, {
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function addTracking({ tracking_courier_id, notice_of_violation_number, tracking_number}) {
    return axios.post(`/delivery/add/tracking/store`, null, {
        params: {
            tracking_courier_id,
            notice_of_violation_number,
            tracking_number
        },
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}