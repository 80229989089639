import axios from 'axios';
import { authHeader } from './auth-header';

export const trafficRecordComponentService = {
    getTrafficRecord,
    getTrafficRecordDispute,
    getVerifyValidateCount,
    getTimelineDispute,
    getLaneList,
    getViolationList,
    getViolation,
    getRegistrationTypeList,
    getMotorVehicleTypeList,
    getMVFileRecord,
    getRejectReasonList,
    getViolationHistory,
    getQueueComponents
};


async function getTrafficRecord(user_id,phase_id){
    const { data } = await axios.get(`/queue/${user_id}/phase/${phase_id}`, {
        headers: authHeader()
    });
    return data;
}

async function getTrafficRecordDispute(user_id){
    const { data } = await axios.get(`/queue/${user_id}/dispute`, {
        headers: authHeader()
    });
    return data;
}

async function getVerifyValidateCount(user_id){
    const { data } = await axios.get(`/queue/${user_id}/verify-validate-count`, {
        headers: authHeader()
    });
    return data;
}

async function getQueueComponents(user_id) {
    const { data } = await axios.get(`/queue/${user_id}/components`, {
        headers: authHeader()
    });
    return data;
}

async function getLaneList(traffic_record_id) {
    const { data } = await axios.get(`/traffic_record_component/camera_capture_info/lane-list/${traffic_record_id}`, {
        headers: authHeader(),
    });
    return data;
}

async function getViolationList(traffic_record_id) {
    const { data } = await axios.get(`/traffic_record_component/violation_penalty/violation-penalty-list/${traffic_record_id}`, {
        headers: authHeader()
    });
    return data;
}

async function getViolation(traffic_record_id) {
    const { data } = await axios.get(`/traffic_record_component/violation_penalty/${traffic_record_id}`, {
        headers: authHeader()
    });
    return data;
}

async function getRegistrationTypeList() {
    const { data } = await axios.get(`/traffic_record_component/registration_number/registration-type-list`, {
        headers: authHeader()
    });
    return data;
}

async function getMotorVehicleTypeList() {
    const { data } = await axios.get(`/traffic_record_component/registration_number/motor-vehicle-type-list`, {
        headers: authHeader()
    });
    return data;
}

async function getMVFileRecord(traffic_record_id) {
    const { data } = await axios.get(`/traffic_record_component/mv_file_record/${traffic_record_id}`, {
        headers: authHeader()
    });
    return data;
}

async function getRejectReasonList() {
    const { data } = await axios.get(`/traffic_record_component/save_or_reject/reject-reason-list`, {
        headers: authHeader()
    });
    return data;
}

async function getViolationHistory(traffic_record_id) {
    const { data } = await axios.get(`/traffic_record_component/violation_history/${traffic_record_id}`, {
        headers: authHeader()
    });
    return data;
}

async function getTimelineDispute(traffic_record_id) {
    const { data } = await axios.get(`/traffic_record_component/mv_file_record/${traffic_record_id}/dispute-timeline`, {
        headers: authHeader()
    });
    return data;
}
