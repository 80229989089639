import { whiteListService } from '../../helpers/authservice/whitelist-lgu.service';
import router from '../../router/index'
import Swal from 'sweetalert2';

export const state = {
    whiteLists: [],
    pagination: {
        per_page: '',
        total: '',
        page: ''
    },
}

export const mutations = {
    FETCH_WHITE_LISTS: (state, payload) => {
        state.whiteLists = payload;
    },
    FETCH_PAGINATION: (state, payload) => {
        state.pagination = payload;
    }
}

export const actions = {
    getWhiteLists: ({ commit, dispatch }, pagination) => {
        dispatch('globals/loading', null, { root: true })

        whiteListService.getAll(pagination)
        .then(({ data, meta }) => {
            commit('FETCH_WHITE_LISTS', data);
            commit('FETCH_PAGINATION', {
                page: meta.current_page,
                total: meta.total,
                per_page: meta.per_page
            });
            dispatch('globals/loaded', null, { root: true })
        })
        .catch((error) => {
            const { message } = error.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true })
        })
    },
    createWhiteList: ({ dispatch }, body) => {
        dispatch('globals/loading', null, { root: true })

        const lgus  = body.lgus && body.lgus.length > 0 ? body.lgus : null;

        const whitelist = { ...body, lgus }

        var formData = new FormData();

        for (const [key, value] of Object.entries(whitelist)) {
            if (value === null) {
                continue;
            }
            if (key === 'lgus') {
                whitelist.lgus.forEach((lgu) => {
                    formData.append('lgus[]', lgu.id);
                })
                continue;
            }

            formData.append(key, value);
        }

        whiteListService.create(formData)
        .then(() => {
            dispatch('notification/clear', null, { root: true });
            router.push('/settings/whitelist-lgu');
            Swal.fire("Success", `You have created a new white list ${body.name}` , "success");
            dispatch('globals/loaded', null, { root: true })
        })
        .catch((error) => {
            const { errors } = error.response.data;
            window.scrollTo(0, 0);
            dispatch('notification/errors', errors, { root: true });
            dispatch('globals/loaded', null, { root: true })
         })
    },
    updateWhiteList: ({ state, dispatch }, body) => {
        dispatch('globals/loading', null, { root: true })

        const lgus  = body.lgus && body.lgus.length > 0 ? body.lgus : null;

        const whitelist = { ...body, lgus }

        var formData = new FormData();
        formData.append('_method', "PUT");

        for (const [key, value] of Object.entries(whitelist)) {
            if (value === null) {
                continue;
            }
            if (key === 'lgus') {
                whitelist.lgus.forEach((lgu) => {
                    formData.append('lgus[]', lgu.id);
                })
                continue;
            }
            formData.append(key, value);
        }

        console.log(formData)

        whiteListService.update(formData, whitelist.id)
        .then(() => {
            window.scrollTo(0, 0);
            dispatch('notification/success', 'White list updated successfully', { root: true });
            dispatch('globals/loaded', null, { root: true });
            dispatch('getWhiteLists', state.pagination);
        })
        .catch((error) => {
           const { errors } = error.response.data;
           window.scrollTo(0, 0);
           dispatch('notification/errors', errors, { root: true });
           dispatch('globals/loaded', null, { root: true })
        })
    },
    deleteWhiteList: ({ state, dispatch }, { id, name }) => {
        dispatch('globals/loading', null, { root: true })

        whiteListService.destroy(id)
        .then(() => {
            dispatch('notification/clear', null, { root: true });
            router.push('/settings/whitelist-lgu');
            dispatch('globals/loaded', null, { root: true })
            Swal.fire("Deleted!", `White list ${name} has been successfully deleted!`, "success");
            dispatch('getWhiteLists', state.pagination);
        })
        .catch((err) => {
            window.scrollTo(0, 0);
            dispatch('notification/error', err, { root: true });
            dispatch('globals/loaded', null, { root: true })
         })
    }
}
