import axios from 'axios';
import { authHeader } from './auth-header';

export const paymentService = {
    getOrderOfPayment,
    getEditOR,
    getAllPrintManifest,
    setPrintManifestPrintStatus
};


async function getOrderOfPayment(notice_of_violation_number){
    const { data } = await axios.get(`/payment/official_receipt`, {
        params: {
            notice_of_violation_number,
        },
        headers: authHeader()
    });
    return data;
}

async function getEditOR(official_receipt_number){
    const { data } = await axios.get(`/payment/official_receipt/show_edit_official_receipt`, {
        params: {
            official_receipt_number,
        },
        headers: authHeader()
    });
    return data;
}

function getAllPrintManifest({ 
    from, 
    to, 
    lgu
}) {
    return axios.get('/report/payment', {
        params: {
            from,
            to,
            lgu
        },
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function setPrintManifestPrintStatus({ 
    batch_id 
}) {
    return axios.post('/delivery/print/manifest/set-printed-status', null, {
        headers: authHeader(),
        params: {
            batch_id
        }
    })
}
