import { lguService } from '../../helpers/authservice/lgu.service';
import router from '../../router/index'

export const state = {
    lgus: [],
    dropdownLGUs: [],
    newLgu: null,
    pagination: {
        per_page: '',
        total: '',
        page: ''
    },
}

export const getters = {
    lgus: (state) => state.lgus,
    dropdownLGUs: (state) => state.dropdownLGUs
}

export const mutations = {
    FETCH_LGUS: (state, payload) => {
        state.lgus = payload;
    },
    FETCH_PAGINATION: (state, payload) => {
        state.pagination = payload;
    },
    FETCH_DROPDOWN_LGUS: (state, payload) => {
        state.dropdownLGUs = payload;
    },
    ADD_LGU: (state, payload) => {
        state.lgus.push(payload);
    }
}

export const actions = {
    getDropdownLGUs: ({ commit, dispatch }) => {
        dispatch('globals/loading', null, { root: true });

        lguService.getDropdown()
        .then(({ data }) => {
            commit('FETCH_DROPDOWN_LGUS', data);
            dispatch('globals/loaded', null, { root: true });
        })
        .catch((error) => {
            const { message } = error.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true });
        })
    },
    getLGUs: ({ commit, dispatch }, pagination) => {
        dispatch('globals/loading', null, { root: true });

        lguService.getAll(pagination)
        .then(({ data, meta }) => {
            commit('FETCH_LGUS', data);
            commit('FETCH_PAGINATION', {
                page: meta.current_page,
                total: meta.total,
                per_page: meta.per_page
            });
            dispatch('globals/loaded', null, { root: true });
        })
        .catch((error) => {
            const { message } = error.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true });
        })
    },
    createLGU: ({ dispatch }, body) => {
        dispatch('globals/loading', null, { root: true });

        const newLGU = { 
            ...body, 
            skip_ovr: body.skip_ovr ? 1 : 0, 
            enable_multiple_violation: body.enable_multiple_violation ? 1 : 0 
        }
        lguService.create(newLGU)
        .then(() => {
            dispatch('notification/clear', null, { root: true });
            dispatch('globals/loaded', null, { root: true });
            router.push('/settings/lgu/list');
        })
        .catch((error) => {
            const { errors } = error.response.data;
            window.scrollTo(0, 0);
            dispatch('notification/errors', errors, { root: true });
            dispatch('globals/loaded', null, { root: true });
         })
    },
    updateLGU: ({ dispatch }, body) => {
        dispatch('globals/loading', null, { root: true });

        const existingLGU = { 
            ...body, 
            skip_ovr: body.skip_ovr ? 1 : 0, 
            enable_multiple_violation: body.enable_multiple_violation ? 1 : 0 
        }
        lguService.update(existingLGU)
        .then(() => {
            window.scrollTo(0, 0);
            dispatch('notification/success', 'LGU updated successfully', { root: true });
            dispatch('globals/loaded', null, { root: true });
        })
        .catch((error) => {
           const { errors } = error.response.data;
           window.scrollTo(0, 0);
           dispatch('notification/errors', errors, { root: true });
           dispatch('globals/loaded', null, { root: true });
        })
    },
    deleteLGU: ({ dispatch }, id) => {
        dispatch('globals/loading', null, { root: true });

        lguService.destroy(id)
        .then(() => {
            dispatch('notification/clear', null, { root: true });
            dispatch('globals/loaded', null, { root: true });
            router.push('/settings/lgu/list');
           
        })
        .catch((error) => {
            const { errors } = error.response.data;
            window.scrollTo(0, 0);
            dispatch('notification/errors', errors, { root: true });
            dispatch('globals/loaded', null, { root: true });
         })

    }
}