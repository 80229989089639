import { trafficRecordComponentService } from '../../helpers/authservice/traffic-record-component.service';
import { uniqBy } from 'lodash';

const getDefaultState = () => {
    return {
        // FORMS : <select>: selected_abc; <input> input_abc
        mount_components:false,
        phase_id:null,
        user_id:null,
        traffic_record: null,
        lane_list: [],
        selected_lane: null,
        violation_list:[],
        violation:null,
        selected_violation:null,
        camera_capture_violation:null,
        registration_type_list:[],
        motor_vehicle_type_list:[],
        input_registration_number:null,
        selected_registration_type:null,
        selected_motor_vehicle_type:null,
        is_valid_registration_number:{
            is_valid:true,
            msg:'Valid',
        },
        mv_file_record:{
            stradcom:{},
            dermalog:{}
        },
        reject_reason_list: [],
        selected_reject_reason : null,
        other_reject_reason : '',
        violation_history:[],
        timeline_dispute:[],
        input_remarks:null,
        queue_components: null,
        input_speed_limit: 0,
        is_reloaded:true,
    }
}

export const state = getDefaultState()

export const getters = {}

export const mutations = {
    RESET_STATE (state) {
        Object.assign(state, getDefaultState())
    },
    SET_MOUNT_COMPONENTS: (state, payload) => {
        state.mount_components = payload;
    },
    SET_TRAFFIC_RECORD: (state, payload) => {
        state.traffic_record = payload;
    },
    SET_PHASE_ID: (state, payload) => {
        state.phase_id = payload;
    },
    SET_USER_ID: (state, payload) => {
        state.user_id = payload;
    },
    SET_LANE_LIST: (state, payload) => {
        state.lane_list = payload;
    },
    SET_CAMERA_CAPTURE_INFO: (state, payload) => {
        state.camera_capture_info = payload;
    },
    SET_SELECTED_LANE: (state, payload) => {
        state.selected_lane = payload;
    },
    SET_VIOLATION_LIST: (state, payload) => {
        state.violation_list = payload;
    },
    SET_SELECTED_VIOLATION: (state, payload) => {
        state.selected_violation = payload;
    },
    SET_CAMERA_CAPTURE_VIOLATION: (state, payload) => {
        state.camera_capture_violation = payload;
    },
    SET_REGISTRATION_TYPE_LIST: (state, payload) => {
        state.registration_type_list = payload;
    },
    SET_MOTOR_VEHICLE_TYPE_LIST: (state, payload) => {
        state.motor_vehicle_type_list = payload;
    },
    SET_INPUT_REGISTRATION_NUMBER: (state, payload) => {
        state.input_registration_number = payload;
    },
    SET_SELECTED_REGISTRATION_TYPE: (state, payload) => {
        state.selected_registration_type = payload;
    },
    SET_SELECTED_MOTOR_VEHICLE_TYPE: (state, payload) => {
        state.selected_motor_vehicle_type = payload;
    },
    SET_IS_VALID_REGISTRATION_NUMBER: (state, payload) => {
        state.is_valid_registration_number = payload;
    },
    SET_MV_FILE_RECORD: (state, payload) => {
        state.mv_file_record = payload;
    },
    CHANGE_MV_FILE_RECORD_OBJECT_VALUES: (state, payload) => {
        state.mv_file_record[payload.key] = payload.value
    },
    SET_OTHER_REJECT_REASON: (state, payload) => {
        state.other_reject_reason = payload;
    },
    SET_SELECTED_REJECT_REASON: (state, payload) => {
        state.selected_reject_reason = payload;
    },
    SET_REJECT_REASON_LIST: (state, payload) => {
        state.reject_reason_list = payload;
    },
    SET_VIOLATION_HISTORY: (state, payload) => {
        state.violation_history = payload;
    },
    SET_TIMELINE_DISPUTE: (state, payload) => {
        state.timeline_dispute = payload;
    },
    SET_INPUT_REMARKS: (state, payload) => {
        state.input_remarks = payload;
    },
    SET_INPUT_SPEED_LIMIT: (state, payload) => {
        state.input_speed_limit = payload;
    },
    SET_IS_RELOADED: (state, payload) => {
        state.is_reloaded = payload;
    },
    SET_QUEUE_COMPONENTS: (state, payload) => {
        if (payload && payload.phases.length) {
            payload.phases = uniqBy(payload.phases, 'id');

            payload.phases.forEach((phase) => {
                switch(phase.id) {
                    case 1: phase.slug = 'input-plate-no'; break;
                    case 2: phase.slug = 'verify-traffic-record'; break;
                    case 3: phase.slug = 'validate'; break;
                    default: phase.slug = '';
                }
            })
        }
        state.queue_components = payload;
    }
}

export const actions = {
    resetState ({ commit }) {
        commit('RESET_STATE')
    },
    reloadTrafficRecord: async ({ commit, dispatch, state }) => {
        commit('SET_IS_RELOADED', false);
        try{
            const data = await trafficRecordComponentService.getTrafficRecord(state.user_id,state.phase_id)
            commit('SET_TRAFFIC_RECORD', data);
            commit('SET_MOUNT_COMPONENTS', true);
            commit('SET_IS_RELOADED', true);
        }catch(error){
            if (error.response) {
                const { message } = error.response.data;
                dispatch('notification/error', message, { root: true });
            }
            commit('SET_IS_RELOADED', true);
        }
       
    },
    getTrafficRecord: async ({ commit, dispatch, state }) => {
        dispatch('globals/loading', null, { root: true });
        try{
            const data = await trafficRecordComponentService.getTrafficRecord(state.user_id,state.phase_id)
            commit('SET_TRAFFIC_RECORD', data);
            commit('SET_MOUNT_COMPONENTS', true);
            if(data && data.lane){
                commit('SET_SELECTED_LANE', parseInt(data.lane));
            }
            if(data && data.registration_number){
                commit('SET_INPUT_REGISTRATION_NUMBER', data.registration_number);
            }
            if(data && data.registration_type_id){
                commit('SET_SELECTED_REGISTRATION_TYPE', parseInt(data.registration_type_id));
            }
            if(data && data.motor_vehicle_type_id){
                commit('SET_SELECTED_MOTOR_VEHICLE_TYPE', parseInt(data.motor_vehicle_type_id)); 
            }
            if(data && data.speed_limit){
                commit('SET_INPUT_SPEED_LIMIT', parseInt(data.speed_limit));
            }
            if(data && data.violation){
                commit('SET_CAMERA_CAPTURE_VIOLATION', data.violation);
            }
            dispatch('globals/loaded', null, { root: true });
        }catch(error){
            if (error.response) {
                const { message } = error.response.data;
                dispatch('notification/error', message, { root: true });
            }
            dispatch('globals/loaded', null, { root: true });
        }
    },
    getTrafficRecordDispute: async ({ commit, dispatch, state }) => {
        dispatch('globals/loading', null, { root: true });
        try{
            const data = await trafficRecordComponentService.getTrafficRecordDispute(state.user_id)
            commit('SET_TRAFFIC_RECORD', data);
            commit('SET_MOUNT_COMPONENTS', true);
            if(data && data.lane){
                commit('SET_SELECTED_LANE', parseInt(data.lane));
            }
            if(data && data.registration_number){
                commit('SET_INPUT_REGISTRATION_NUMBER', data.registration_number);
            }
            if(data && data.registration_type_id){
                commit('SET_SELECTED_REGISTRATION_TYPE', parseInt(data.registration_type_id));
            }
            if(data && data.motor_vehicle_type_id){
                commit('SET_SELECTED_MOTOR_VEHICLE_TYPE', parseInt(data.motor_vehicle_type_id)); 
            }
            if(data && data.speed_limit){
                commit('SET_INPUT_SPEED_LIMIT', parseInt(data.speed_limit));
            }
            if(data && data.violation){
                commit('SET_CAMERA_CAPTURE_VIOLATION', data.violation);
            }
            dispatch('globals/loaded', null, { root: true });
        }catch(error){
            if (error.response) {
                const { message } = error.response.data;
                dispatch('notification/error', message, { root: true });
            }
            dispatch('globals/loaded', null, { root: true });
        }
    },
    getQueueComponents: async ({ commit, dispatch, state }) => {
        dispatch('globals/loading', null, { root: true });

        try {
            const { data } = await trafficRecordComponentService.getQueueComponents(state.user_id)
            commit('SET_QUEUE_COMPONENTS', data);
            dispatch('globals/loaded', null, { root: true });
        } catch (error) {
            if (error.response) {
                const { message } = error.response.data;
                dispatch('notification/error', message, { root: true });
            }
            dispatch('globals/loaded', null, { root: true });
        }
    },
    getLaneList: async ({ commit, dispatch, state }) => {
        dispatch('globals/loading', null, { root: true });

        try{
            const {data} = await trafficRecordComponentService.getLaneList(state.traffic_record.id)
            commit('SET_LANE_LIST', data);
            dispatch('globals/loaded', null, { root: true });
        }catch(error){
            if (error.response) {
                const { message } = error.response.data;
                commit('SET_MOUNT_COMPONENTS', false);
                dispatch('notification/error', message, { root: true });
            }
            dispatch('globals/loaded', null, { root: true });
        }
    },
    getViolationList: async ({ commit, dispatch, state }) => {
        dispatch('globals/loading', null, { root: true });

        try{
            const {data} = await trafficRecordComponentService.getViolationList(state.traffic_record.id)
            commit('SET_VIOLATION_LIST', data);
            dispatch('globals/loaded', null, { root: true });
        }catch(error){
            if (error.response) {
                const { message } = error.response.data;
                commit('SET_MOUNT_COMPONENTS', false);
                dispatch('notification/error', message, { root: true });
            }
            
            dispatch('globals/loaded', null, { root: true });
        }
    },
    getViolation: async ({ commit, dispatch, state }) => {
        dispatch('globals/loading', null, { root: true });

        try{
            const {data} = await trafficRecordComponentService.getViolation(state.traffic_record.id)
            if(data && data.id){
                commit('SET_SELECTED_VIOLATION', data.id);
                dispatch('globals/loaded', null, { root: true });
            }
        }catch(error){
            if (error.response) {
                const { message } = error.response.data;
                commit('SET_MOUNT_COMPONENTS', false);
                dispatch('notification/error', message, { root: true });
            }
            dispatch('globals/loaded', null, { root: true });
        }
    },
    getRegistrationTypeList: async ({ commit, dispatch }) => {
        dispatch('globals/loading', null, { root: true });

        try{
            const {data} = await trafficRecordComponentService.getRegistrationTypeList()
            commit('SET_REGISTRATION_TYPE_LIST', data);
            dispatch('globals/loaded', null, { root: true });
        }catch(error){
            if (error.response) {
                const { message } = error.response.data;
                commit('SET_MOUNT_COMPONENTS', false);
                dispatch('notification/error', message, { root: true });
            }
            dispatch('globals/loaded', null, { root: true });
        }
    },
    getMotorVehicleTypeList: async ({ commit, dispatch }) => {
        dispatch('globals/loading', null, { root: true });

        try{
            const {data} = await trafficRecordComponentService.getMotorVehicleTypeList()
            commit('SET_MOTOR_VEHICLE_TYPE_LIST', data);
            dispatch('globals/loaded', null, { root: true });
        }catch(error){
            if (error.response) { 
                const { message } = error.response.data;
                commit('SET_MOUNT_COMPONENTS', false);
                dispatch('notification/error', message, { root: true });
            }
            dispatch('globals/loaded', null, { root: true });
        }
    },
    getMVFileRecord: async ({ commit, dispatch, state }) => {
        dispatch('globals/loading', null, { root: true });

        try{
            const {data} = await trafficRecordComponentService.getMVFileRecord(state.traffic_record.id)
            if(data){
                commit('SET_MV_FILE_RECORD', data);
            }
            dispatch('globals/loaded', null, { root: true });
        }catch(error){
            if (error.response) {
                const { message } = error.response.data;
                commit('SET_MOUNT_COMPONENTS', false);
                dispatch('notification/error', message, { root: true });
            }
            dispatch('globals/loaded', null, { root: true });
        }
    },
    getRejectReasonList: async ({ commit, dispatch }) => {
        dispatch('globals/loading', null, { root: true });

        try{
            const {data} = await trafficRecordComponentService.getRejectReasonList()
            commit('SET_REJECT_REASON_LIST', data);
            dispatch('globals/loaded', null, { root: true });
        }catch(error){
            if (error.response) {
                const { message } = error.response.data;
                commit('SET_MOUNT_COMPONENTS', false);
                dispatch('notification/error', message, { root: true });
            }
            dispatch('globals/loaded', null, { root: true });
        }
    },
    getViolationHistory: async ({ commit, dispatch, state }) => {
        dispatch('globals/loading', null, { root: true });

        try{
            const {data} = await trafficRecordComponentService.getViolationHistory(state.traffic_record.id)
            commit('SET_VIOLATION_HISTORY', data);
            dispatch('globals/loaded', null, { root: true });
        }catch(error){
            if (error.response) {
                const { message } = error.response.data;
                commit('SET_MOUNT_COMPONENTS', false);
                dispatch('notification/error', message, { root: true });
            }
            dispatch('globals/loaded', null, { root: true });
        }
    },
    getTimelineDispute: async ({ commit, dispatch, state }) => {
        dispatch('globals/loading', null, { root: true });

        try{
            const {data} = await trafficRecordComponentService.getTimelineDispute(state.traffic_record.id)
            commit('SET_TIMELINE_DISPUTE', data);
            dispatch('globals/loaded', null, { root: true });
        }catch(error){
            if (error.response) {
                const { message } = error.response.data;
                commit('SET_MOUNT_COMPONENTS', false);
                dispatch('notification/error', message, { root: true });
            }
            dispatch('globals/loaded', null, { root: true });
        }
    },
}
