import axios from 'axios';
import { authHeader } from './auth-header';

export const cameraService = {
    getAllCameras,
    getAllCameraTypes,
    getAllDropdownCameraTypes,
    getAllCameraSort,
    getAllCameraLocations,
    createCamera,
    updateCamera,
    destroyCamera,
    createCameraType,
    createCameraLocation,
    getCameraLocation,
    updateCameraLocation,
    destroyCameraLocation,
    updateCameraSort,
    updateCameraLocationSort,
    updateCameraType,
    destroyCameraType,
    getCameraProcess,
    getCameraProcessDetails
};

function getAllCameras({ pageSize, page, search, orderBy, sortColumn }) {
    return axios.get('/cameras', {
        params: {
            pageSize,
            page,
            search,
            orderBy,
            sortColumn
        },
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function getAllCameraTypes({ pageSize, page, search, orderBy, sortColumn }) {
    return axios.get('/camera_types', {
        params: {
            pageSize,
            page,
            search,
            orderBy,
            sortColumn
        },
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function getAllDropdownCameraTypes() {
    return axios.get('/camera_types', {
        params: {
            pageSize: 100
        },
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function createCamera(data) {
    return axios.post('/cameras', data, {
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function updateCamera(data) {
    return axios.put(`/cameras/${data.id}`, data, {
        headers: authHeader()
    })
}

function destroyCamera(id) {
    return axios.delete(`/cameras/${id}`, {
        headers: authHeader()
    })
}

function createCameraType(data) {
    return axios.post('/camera_types', data, {
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function getAllCameraSort() {
    return axios.get('/camera/sort', {
        headers: authHeader()
    })
}

function getAllCameraLocations() {
    return axios.get('/camera_locations?pageSize=1000', {
        headers: authHeader()
    })
}

function createCameraLocation(data) {
    return axios.post('/camera_locations', data, {
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function getCameraLocation(id) {
    return axios.get(`/camera_locations/${id}`, {
        headers: authHeader()
    })
}

function updateCameraLocation(data) {
    return axios.put(`/camera_locations/${data.id}`, data, {
        headers: authHeader()
    })
}

function destroyCameraLocation(id) {
    return axios.delete(`/camera_locations/${id}`, {
        headers: authHeader()
    })
}

function updateCameraSort(newOrder) {
    return axios.put('/camera/sort', {
        camera_queue_order: newOrder
    }, {
        headers: authHeader()
    });
}

function updateCameraLocationSort(newOrder) {
    return axios.put('/camera_locations/update-queue-order', {
        camera_location_queue_order: newOrder
    }, {
        headers: authHeader()
    });
}

function updateCameraType(data) {
    return axios.put(`/camera_types/${data.id}`, data, {
        headers: authHeader()
    })
}

function destroyCameraType(id) {
    return axios.delete(`/camera_types/${id}`, {
        headers: authHeader()
    })
}

async function getCameraProcess() {
    const { data } = await axios.get('/camera/process', {
        params: {
            pageSize: 100
        },
        headers: authHeader()
    })

    return data;
}

async function getCameraProcessDetails(cameraTypeId) {
    const { data } = await axios.get(`/camera/process/${cameraTypeId}`, {
        headers: authHeader()
    })

    return data;
}

