import { cameraService } from '../../helpers/authservice/camera.service';
import router from '../../router/index';
import Swal from 'sweetalert2';

export const state = {
    cameras: [],
    cameraTypes: [],
    cameraType: {
        id: '',
        name: '',
        process: '',
        folder: '',
        file_setting_id: '',
        updated_at: null
    },
    dropdownCameraTypes: [],
    cameraPagination: {
        per_page: '',
        total: '',
        page: ''
    },
    cameraTypesPagination: {
        per_page: '',
        total: '',
        page: ''
    },
    cameraSort: [],
    cameraLocations: [],
    cameraLocation: {
        id: '',
        name: '',
        remarks: '',
        status: false,
        updated_at: null
    },
    cameraProcess: [],
    cameraProcessDetails: null
}

export const getters = {
    cameras: (state) => state.cameras,
    cameraTypes: (state) => state.cameraTypes,
    cameraType: (state) => state.cameraType,
    cameraSort: (state) => state.cameraSort,
    cameraLocations: (state) => state.cameraLocations,
    cameraLocation: (state) => state.cameraLocation,
}

export const mutations = {
    FETCH_CAMERAS: (state, payload) => {
        state.cameras = payload;
    },
    FETCH_CAMERA_TYPES: (state, payload) => {
        state.cameraTypes = payload;
    },
    FETCH_DROPDOWN_CAMERA_TYPES: (state, payload) => {
        state.dropdownCameraTypes = payload;
    },
    FETCH_CAMERA_PAGINATION: (state, payload) => {
        state.cameraPagination = payload;
    },
    FETCH_CAMERA_TYPES_PAGINATION: (state, payload) => {
        state.cameraTypesPagination = payload;
    },
    FETCH_CAMERA_SORT: (state, payload) => {
        state.cameraSort = payload;
    },
    FETCH_CAMERA_LOCATIONS: (state, payload) => {
        state.cameraLocations = payload;
    },
    FETCH_CAMERA_LOCATION: (state, payload) => {
        state.cameraLocation = payload;
    },
    UPDATE_CAMERA_SORT: (state, payload) => {
        state.cameraSort = payload;
    },
    UPDATE_CAMERA_LOCATION_SORT: (state, payload) => {
        state.cameraLocations = payload;
    },
    FETCH_CAMERA_PROCESS: (state, payload) => {
        state.cameraProcess = payload;
    },
    FETCH_CAMERA_PROCESS_DETAILS: (state, payload) => {
        state.cameraProcessDetails = payload;
    }
}

export const actions = {
    getCameras: ({ commit, dispatch }, pagination) => {
        dispatch('globals/loading', null, { root: true });
        cameraService.getAllCameras(pagination)
        .then(({ data, meta }) => {
            commit('FETCH_CAMERA_PAGINATION', {
                page: meta.current_page,
                total: meta.total,
                per_page: meta.per_page
            })
            commit('FETCH_CAMERAS', data);
            dispatch('globals/loaded', null, { root: true });
        })
        .catch((error) => {
            const { message } = error.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true });
        })
    },
    getCameraTypes: ({ commit, dispatch }, pagination) => {
        dispatch('globals/loading', null, { root: true });

        cameraService.getAllCameraTypes(pagination)
        .then(({ data, meta }) => {
            commit('FETCH_CAMERA_TYPES_PAGINATION', {
                page: meta.current_page,
                total: meta.total,
                per_page: meta.per_page
            })
            commit('FETCH_CAMERA_TYPES', data);
            dispatch('globals/loaded', null, { root: true });
        })
        .catch((error) => {
            const { message } = error.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true });
        })
    },
    getCameraSort: ({ commit, dispatch }) => {
        dispatch('globals/loading', null, { root: true });

        cameraService.getAllCameraSort()
        .then(( { data }) => {
            commit('FETCH_CAMERA_SORT', data.data);
            dispatch('globals/loaded', null, { root: true });
        })
        .catch((error) => {
            const { message } = error.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true });
        })
    },
    getCameraLocations: ({ commit, dispatch }) => {
        dispatch('globals/loading', null, { root: true });
        cameraService.getAllCameraLocations()
        .then(( { data }) => {
            commit('FETCH_CAMERA_LOCATIONS', data.data);
            dispatch('globals/loaded', null, { root: true });
        })
        .catch((error) => {
            const { message } = error.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true });
        })
    },
    createCameraLocation: ({ dispatch }, body) => {
        dispatch('globals/loading', null, { root: true });

        cameraService.createCameraLocation(body)
        .then(() => {
            dispatch('notification/clear', null, { root: true });
            router.push('/camera/locations/list');
            dispatch('globals/loaded', null, { root: true });
        })
        .catch((error) => {
            const { errors } = error.response.data;
            window.scrollTo(0, 0);
            dispatch('notification/errors', errors, { root: true });
            dispatch('globals/loaded', null, { root: true });
        })
    },
    getCameraLocation: ({ commit, dispatch }, id) => {
        dispatch('globals/loading', null, { root: true });
        cameraService.getCameraLocation(id)
        .then(({ data }) => {
            commit('FETCH_CAMERA_LOCATION', data.data);
            dispatch('notification/clear', null, { root: true });
            dispatch('globals/loaded', null, { root: true });
        })
        .catch((error) => {
            const { message } = error.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true });
        })
    },
    deleteCameraLocation: ({ dispatch }, { id, name}) => {
        dispatch('globals/loading', null, { root: true })

        cameraService.destroyCameraLocation(id)
        .then(() => {
            dispatch('notification/clear', null, { root: true });
            dispatch('getCameraLocations');
            router.push('/camera/locations/list');
            dispatch('globals/loaded', null, { root: true });
            Swal.fire("Deleted!", `Camera location ${name} has been successfully deleted!`, "success");
        })
        .catch((error) => {
            const { errors } = error.response.data;
            window.scrollTo(0, 0);
            dispatch('notification/errors', errors, { root: true });
            dispatch('globals/loaded', null, { root: true })
         })
    },
    getDropdownCameraTypes: ({ commit, dispatch }) => {
        dispatch('globals/loading', null, { root: true });

        cameraService.getAllDropdownCameraTypes()
        .then(({ data }) => {
            commit('FETCH_DROPDOWN_CAMERA_TYPES', data);
            dispatch('globals/loaded', null, { root: true });
        })
        .catch((error) => {
            const { message } = error.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true });
        })
    },
    createCamera: ({ dispatch }, body) => {
        dispatch('globals/loading', null, { root: true });

        const { id, camera_id, location, lanes, lgu_id, camera_type_id, is_enabled} = body;

        const newCamera = {
            id, camera_id, location, lanes, lgu_id, camera_type_id, is_enabled
        }

        cameraService.createCamera(newCamera)
        .then(() => {
            dispatch('notification/clear', null, { root: true });
            router.push('/camera/dashboard/list');
            dispatch('globals/loaded', null, { root: true });
            Swal.fire("Success", `You have created a new camera ${body.location}` , "success");
        })
        .catch((error) => {
            dispatch('notification/clear', null, { root: true });
            let errorsArray = [];
            const { errors } = error.response.data;
            for (error in errors) {
                errorsArray = [ ...errorsArray, errors[error][0]]
            }
            window.scrollTo(0, 0);
            dispatch('notification/errors', errorsArray, { root: true });
            dispatch('globals/loaded', null, { root: true })
        })
    },
    updateCamera: ({ dispatch }, body) => {
        dispatch('globals/loading', null, { root: true })
        const { id, camera_id, location, lanes, lgu_id, camera_type_id, is_enabled, mode_status} = body;

        const existingCamera = {
            id, camera_id, location, lanes, lgu_id, camera_type_id, is_enabled, mode_status
        }

        cameraService.updateCamera(existingCamera)
        .then(() => {
            window.scrollTo(0, 0);
            dispatch('notification/clear', null, { root: true });
            dispatch('notification/success', 'Camera updated successfully', { root: true });
            dispatch('globals/loaded', null, { root: true })
        })
        .catch((error) => {
            dispatch('notification/clear', null, { root: true });
            let errorsArray = [];
            const { errors } = error.response.data;
            for (error in errors) {
                errorsArray = [ ...errorsArray, errors[error][0]]
            }
            window.scrollTo(0, 0);
            dispatch('notification/errors', errorsArray, { root: true });
            dispatch('globals/loaded', null, { root: true })
        })
    },
    deleteCamera: ({ state, dispatch }, { id, location }) => {
        dispatch('globals/loading', null, { root: true })

        cameraService.destroyCamera(id)
        .then(() => {
            dispatch('notification/clear', null, { root: true });
            dispatch('getCameras', state.cameraPagination);
            router.push('/camera/dashboard/list');
            dispatch('globals/loaded', null, { root: true });
            Swal.fire("Deleted!", `Camera ${location} has been successfully deleted!`, "success");
        })
        .catch((error) => {
            const { errors } = error.response.data;
            window.scrollTo(0, 0);
            dispatch('notification/errors', errors, { root: true });
            dispatch('globals/loaded', null, { root: true })
         })
    },
    createCameraType: ({ dispatch }, body) => {
        dispatch('globals/loading', null, { root: true })

        cameraService.createCameraType(body)
        .then(() => {
            dispatch('notification/clear', null, { root: true });
            router.push('/camera/type/list');
            Swal.fire("Success", `You have created a new camera type ${body.name}` , "success");
            dispatch('globals/loaded', null, { root: true })
        })
        .catch((error) => {
            const { errors } = error.response.data;
            window.scrollTo(0, 0);
            dispatch('notification/errors', errors, { root: true });
            dispatch('globals/loaded', null, { root: true })
         })
    },
    updateCameraType: ({ state, dispatch }, body) => {
        dispatch('globals/loading', null, { root: true })

        cameraService.updateCameraType(body)
        .then(() => {
            window.scrollTo(0, 0);
            dispatch('notification/success', 'Camera type updated successfully', { root: true });
            dispatch('globals/loaded', null, { root: true })
            dispatch('getCameraTypes', state.cameraTypesPagination);
        })
        .catch((error) => {
           const { errors } = error.response.data;
           window.scrollTo(0, 0);
           dispatch('notification/errors', errors, { root: true });
           dispatch('globals/loaded', null, { root: true })
        })
    },
    deleteCameraType: ({ state, dispatch }, { id, name }) => {
        dispatch('globals/loading', null, { root: true })

        cameraService.destroyCameraType(id)
        .then(() => {
            dispatch('notification/clear', null, { root: true });
            dispatch('getCameraTypes', state.cameraTypesPagination);
            router.push('/camera/type/list');
            dispatch('globals/loaded', null, { root: true });
            Swal.fire("Deleted!", `Camera type ${name} has been successfully deleted!`, "success");
        })
        .catch((error) => {
            const { errors } = error.response.data;
            window.scrollTo(0, 0);
            dispatch('notification/errors', errors, { root: true });
            dispatch('globals/loaded', null, { root: true })
         })
    },
    updateCameraSort: ({ state, dispatch }) => {
        const newOrder = state.cameraSort && state.cameraSort.map((sort) => sort.id);

        cameraService.updateCameraSort(newOrder)
       .then(() => {
            dispatch('notification/clear', null, { root: true });
       })
       .catch((error) => {
            const { errors } = error.response.data;
            window.scrollTo(0, 0);
            dispatch('notification/errors', errors, { root: true });
        })
    },
    updateCameraLocationSort: ({ state, dispatch }) => {
        const newOrder = state.cameraLocations && state.cameraLocations.map((location) => location.id);
        cameraService.updateCameraLocationSort(newOrder)
       .then(() => {
            dispatch('notification/clear', null, { root: true });
       })
       .catch((error) => {
            const { errors } = error.response.data;
            window.scrollTo(0, 0);
            dispatch('notification/errors', errors, { root: true });
        })
    },
    updateCameraLocation: ({ dispatch }, existingLocation) => {
        dispatch('globals/loading', null, { root: true });

        cameraService.updateCameraLocation(existingLocation)
        .then(() => {
            dispatch('notification/clear', null, { root: true });
            dispatch('globals/loaded', null, { root: true });
            dispatch('notification/success', 'Camera location updated successfully', { root: true });
            dispatch('getCameraLocations');
        })
        .catch((err) => {
            const { error } = err.response.data;
            window.scrollTo(0, 0);
            dispatch('notification/error', error, { root: true });
            dispatch('globals/loaded', null, { root: true });
        })
    },
    getCameraProcess: async ({ commit, dispatch }) => {
        dispatch('globals/loading', null, { root: true });

        try {
            const { data } = await cameraService.getCameraProcess();
            commit('FETCH_CAMERA_PROCESS', data);
            dispatch('notification/clear', null, { root: true });
            dispatch('globals/loaded', null, { root: true });
        } catch (error) {
            const { message } = error.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true });
        }
    },
    getCameraProcessDetails: async ({ commit, dispatch }, cameraTypeId) => {
        dispatch('globals/loading', null, { root: true });

        try {
            const { data } = await cameraService.getCameraProcessDetails(cameraTypeId);
            commit('FETCH_CAMERA_PROCESS_DETAILS', data);
            dispatch('notification/clear', null, { root: true });
            dispatch('globals/loaded', null, { root: true });
        } catch (error) {
            const { message } = error.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true });
        }
    }
}
