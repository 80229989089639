import { novReturnCard } from '../../helpers/authservice/nov-return-card.service';
// import router from '../../router/index';

export const state = {
    novReturnCards: [],
    pagination: {
        per_page: 10,
        total: 11,
        page: 1
    },
    rcTemplate: null
}

export const getters = {
    novReturnCards: (state) => state.novReturnCards,
}

export const mutations = {
    FETCH_NOV_RETURN_CARDS: (state, payload) => {
        state.novReturnCards = [];
        state.novReturnCards = payload;
    },
    FETCH_PAGINATION: (state, payload) => {
        state.pagination = payload;
    },
    SET_RC_TEMPLATE: (state, payload) => {
        state.rcTemplate = payload;
    }
}

export const actions = {
    getNOVReturnCards({ commit, dispatch }, pagination) {
        dispatch('globals/loading', null, { root: true });

        novReturnCard.getAll(pagination)
        .then(({ data, meta }) => {
            commit('FETCH_NOV_RETURN_CARDS', data);
            commit('FETCH_PAGINATION', {
                page: meta.current_page,
                total: meta.total,
                per_page: meta.per_page
            });
            dispatch('globals/loaded', null, { root: true })
        })
        .catch((error) => {
            const { message } = error.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true })
        })
    },
    fetchDownloadableRCTemplate({ commit, dispatch }, { batch_id, type, location }) {
        dispatch('globals/loading', null, { root: true });

        novReturnCard.fetchDownloadableRCTemplate({ batch_id, type, location })
        .then(({ data }) => {
            commit('SET_RC_TEMPLATE', data);
            dispatch('globals/loaded', null, { root: true })
            dispatch('notification/clear', null, { root: true })
        })
        .catch((error) => {
            dispatch('globals/loaded', null, { root: true })
            const { message } = error.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true })
        })
    },
    setRCPrintStatus: ({ state, dispatch }, { batch_id, type, location }) => {
        dispatch('globals/loading', null, { root: true });

        novReturnCard.setRCPrintStatus({ batch_id, type, location  })
        .then(() => {
            dispatch('getNOVReturnCards', state.pagination);
            dispatch('globals/loaded', null, { root: true });
        })
        .catch((err) => {
            const { message } = err.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true });
        })
    },
    setNOVPrintStatus: ({ state, dispatch }, { batch_id, start, end }) => {
        dispatch('globals/loading', null, { root: true });

        novReturnCard.setNOVPrintStatus({ batch_id, start, end  })
        .then(() => {
            dispatch('getNOVReturnCards', state.pagination);
            dispatch('globals/loaded', null, { root: true });
        })
        .catch((err) => {
            const { message } = err.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true });
        })
    }
}