import { userService } from '../../helpers/authservice/user.service';
import router from '../../router/index'
import Swal from 'sweetalert2';

const currentUser = JSON.parse(localStorage.getItem('user'));

export const state = {
    users: [],
    user: {
        email: '',
        name: '',
        image: null,
        phone_number: '',
        signature: null,
        roles: null,
        lgus: null,
        mode: null
    },
    pagination: {
        per_page: '',
        total: '',
        page: ''
    },
    userRoles: []
}

export const getters = {
    users: (state) => state.users,
    user: (state) => state.user,
    message: (state) => state.message
}

export const mutations = {
    FETCH_USERS: (state, payload) => {
        state.users = payload;
    },
    FETCH_PAGINATION: (state, payload) => {
        state.pagination = payload;
    },
    FETCH_USER: (state, payload) => {
        state.user = payload;
    },
    FETCH_USER_ROLES: (state, payload) => {
        state.userRoles = payload;
    }
}

export const actions = {
    getUser: ({ dispatch, commit, state }, id) => {
        state.user = {
            email: '',
            name: '',
            image: null,
            phone_number: '',
            signature: null,
            roles: null,
            lgus: null,
            mode: null
        }

        dispatch('globals/loading', null, { root: true });

        userService.getOne(id)
        .then(({ user } ) => {
            commit('FETCH_USER', user);
            dispatch('notification/clear', null, { root: true });
            dispatch('globals/loaded', null, { root: true });
        })
        .catch((error) => {
            const { message } = error.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true });
        })
    },
    getUserRoles: ({ dispatch, commit }, id) => {
        dispatch('globals/loading', null, { root: true });

        userService.getUserRole(id)
        .then(({ data } ) => {
            commit('FETCH_USER_ROLES', data);
            dispatch('notification/clear', null, { root: true });
            dispatch('globals/loaded', null, { root: true });
        })
        .catch((error) => {
            const { message } = error.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true });
        })
    },
    getUsers: ({ dispatch, commit }, { size, search, sortColumn, orderBy, page }) => {
        dispatch('globals/loading', null, { root: true });

        userService.getAll({ size, search, sortColumn, orderBy, page })
        .then(({ data, meta }) => {
            commit('FETCH_PAGINATION', {
                page: meta.current_page,
                total: meta.total,
                per_page: meta.per_page
            })
            commit('FETCH_USERS', data);
            dispatch('globals/loaded', null, { root: true });
        })
        .catch((error) => {
            const { message } = error.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true });
        })
    },
    createUser: ({ dispatch }, newUser) => {
        dispatch('globals/loading', null, { root: true });

        const roles = newUser.roles && newUser.roles.length > 0 ? newUser.roles : null;
        const lgus  = newUser.roles && newUser.lgus.length > 0 ? newUser.lgus : null;

        const user = { ...newUser, roles, lgus }

        var formData = new FormData();

        for (const [key, value] of Object.entries(user)) {
            if (value === null) {
                continue;
            }
            if (key === 'lgus') {
                user.lgus.forEach((lgu) => {
                    formData.append('lgus[]', lgu.id);
                })
                continue;
            }

            if (key === 'roles') {
                user.roles.forEach((role) => {
                    formData.append('roles[]', role.id);
                })
                continue;
            }

            formData.append(key, value);
        }

        userService.create(formData)
        .then(() => {
            dispatch('notification/clear', null, { root: true });

            if (user.email) {
                Swal.fire(
                    "Success",
                    "You have successfully created a new account. Please check the user's email inbox for the temporary password.",
                    "success");
            } else if (user.username && !user.email) {
                Swal.fire(
                    "Success",
                    "You have successfully created a new account. Please contact administrator for the temporary password.",
                    "success");
            }
            router.push('/user-management/users/list');
            dispatch('globals/loaded', null, { root: true });
        })
        .catch((error) => {
           const { errors } = error.response.data;
           window.scrollTo(0, 0);
           dispatch('notification/errors', errors, { root: true });
           dispatch('globals/loaded', null, { root: true });
        })
    },
    updateUser: ({ dispatch }, existingUser) => {
        dispatch('globals/loading', null, { root: true });

        const roles = existingUser.roles && existingUser.roles.length > 0 ? existingUser.roles : null;
        const lgus  = existingUser.roles && existingUser.lgus.length > 0 ? existingUser.lgus : null;

        const user = { ...existingUser, roles, lgus }

        var formData = new FormData();

        for (const [key, value] of Object.entries(user)) {
            if (value === null) {
                continue;
            }
            if (key === 'lgus') {
                user.lgus.forEach((lgu) => {
                    formData.append('lgus[]', lgu.id);
                })
                continue;
            }

            if (key === 'roles') {
                user.roles.forEach((role) => {
                    formData.append('roles[]', role.id);
                })
                continue;
            }

            formData.append(key, value);
        }

        userService.updateUser(formData, existingUser.id)
        .then((data) => {
            window.scrollTo(0, 0);
            dispatch('notification/success', 'User updated successfully', { root: true });
            dispatch('globals/loaded', null, { root: true });

            if (currentUser && currentUser.id === existingUser.id) {
                localStorage.setItem('user', JSON.stringify(data.user));
                Swal.fire(
                    "Success",
                    "User updated successfully",
                    "success").then((result) => {
                        if (result.value) {
                            location.reload(true);
                        }
                    });

            }
        })
        .catch((error) => {
           const { errors } = error.response.data;
           window.scrollTo(0, 0);
           dispatch('notification/errors', errors, { root: true });
           dispatch('globals/loaded', null, { root: true });
        })
    },
    deleteUser: ({ dispatch }, { id, name }) => {
        dispatch('globals/loading', null, { root: true });

        userService.deleteUser(id)
        .then(() => {
            window.scrollTo(0, 0);
            dispatch('notification/clear', null, { root: true });
            Swal.fire(
                "Success",
                `"${name}" has been deleted from the system`,
                "success");
            router.push('/user-management/users/list');
            dispatch('globals/loaded', null, { root: true });
        })
        .catch((error) => {
           dispatch('notification/clear', null, { root: true });
           const { errors } = error.response.data;
           window.scrollTo(0, 0);
           dispatch('notification/errors', errors, { root: true });
           dispatch('globals/loaded', null, { root: true });
        })
    },
    updatePassword: ({ dispatch }, existingUser) => {
        dispatch('globals/loading', null, { root: true });

        userService.updateUser(existingUser)
        .then(() => {
            window.scrollTo(0, 0);
            dispatch('notification/success', 'User password updated successfully', { root: true });
            dispatch('globals/loaded', null, { root: true });
        })
        .catch((error) => {
           const { errors } = error.response.data;
           window.scrollTo(0, 0);
           dispatch('notification/errors', errors, { root: true });
           dispatch('globals/loaded', null, { root: true });
        })
    },
    activateUser: ({ state, dispatch }, { id, name }) => {
        dispatch('globals/loading', null, { root: true });

        userService.activateUser(id)
        .then(() => {
            dispatch('notification/clear', null, { root: true });
            window.scrollTo(0, 0);
            Swal.fire(
                "Success",
                `${name}'s account has been activated`,
                "success");
            dispatch('getUsers', state.pagination);
            dispatch('globals/loaded', null, { root: true });
        })
        .catch((error) => {
           dispatch('notification/clear', null, { root: true });
           const { errors } = error.response.data;
           window.scrollTo(0, 0);
           dispatch('notification/errors', errors, { root: true });
           dispatch('globals/loaded', null, { root: true });
        })
    },
    deactivateUser: ({ state, dispatch }, { id, name }) => {
        dispatch('globals/loading', null, { root: true });

        userService.deactivateUser(id)
        .then(() => {
            dispatch('notification/clear', null, { root: true });
            window.scrollTo(0, 0);
            Swal.fire(
                "Success",
                `${name}'s account has been deactivated`,
                "success");
            dispatch('getUsers', state.pagination);
            dispatch('globals/loaded', null, { root: true });
        })
        .catch((error) => {
           dispatch('notification/clear', null, { root: true });
           const { errors } = error.response.data;
           window.scrollTo(0, 0);
           dispatch('notification/errors', errors, { root: true });
           dispatch('globals/loaded', null, { root: true });
        })
    },
    updateUserLGUAccess: ({ dispatch }, { user_id, name, lgus, roles}) => {
        dispatch('globals/loading', null, { root: true });

        userService.updateUserLGUAccess({ user_id, lgus, roles })
        .then(() => {
            dispatch('globals/loaded', null, { root: true });
            Swal.fire(
                "Success",
                `${name}'s LGU access has been updated`,
                "success");
        })
        .catch((error) => {
           const { errors } = error.response.data;
           window.scrollTo(0, 0);
           dispatch('notification/errors', errors, { root: true });
           dispatch('globals/loaded', null, { root: true });
        })
    },
    updateMode: ({ dispatch }, data) => {
        return new Promise((resolve, reject) => {
            dispatch('globals/loading', null, { root: true });

            userService.updateMode(data)
            .then((response) => {
                window.scrollTo(0, 0);
                dispatch('notification/success', 'Mode succesfully change.', { root: true });
                dispatch('globals/loaded', null, { root: true });
                resolve(response);
            })
            .catch((error) => {
               const { errors } = error.response.data;
               window.scrollTo(0, 0);
               dispatch('notification/errors', errors, { root: true });
               dispatch('globals/loaded', null, { root: true });
               reject(error);
           })
        })
    },
}
