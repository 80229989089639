import axios from 'axios';
import { authHeader } from './auth-header';

export const deliveryService = {
    getAllPrintManifest,
    setPrintManifestPrintStatus
}

function getAllPrintManifest({ 
    pageSize, 
    page, 
    search, 
    orderBy, 
    sortColumn  
}) {
    return axios.get('/delivery/print/manifest', {
        params: {
            pageSize,
            page,
            search,
            orderBy,
            sortColumn
        },
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function setPrintManifestPrintStatus({ 
    batch_id 
}) {
    return axios.post('/delivery/print/manifest/set-printed-status', null, {
        headers: authHeader(),
        params: {
            batch_id
        }
    })
}
