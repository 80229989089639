import axios from 'axios';
import { authHeader } from './auth-header';
import { uniqBy } from 'lodash';

export const authService = {
    login,
    logout,
    register,
    resetPassword,
    changePassword,
    getUserRoles
};

function login(loginInfo) {
    return axios.post('/login', loginInfo)
    .then(({ data }) => {
        if (data.token) {
            localStorage.setItem('user', JSON.stringify(data.user));
            localStorage.setItem('token', JSON.stringify(data.token));
        }

        return { user: data.user, token: data.token };
    })
}

function getUserRoles(user_id) {
    return axios.get(`/users/${user_id}/roles`, {
        headers: authHeader()
    })
    .then(({ data }) => {
        const collections = data.data
        const filteredPermissions = collections && collections.length ? collections.map((collection) => [ ...collection.permissions ]) : [];
        const flattenPermissions = [].concat(...filteredPermissions);
        const uniquePermissions = uniqBy(flattenPermissions, 'slug');
        localStorage.setItem('roles', JSON.stringify(uniquePermissions));
        return uniquePermissions;
    })
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('roles');
}

function register(user) {
    return axios.post('/register', user, {
        headers: authHeader()
    })
    .then(({ data }) => {
        console.log(data);
        return data.user;
    })
}

function resetPassword(data) {
    return axios.post('/forgot_password', data)
    .then(({ data }) => {
        return data;
    })
}

function changePassword(data) {
    return axios.post('/change_password', data, {
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}