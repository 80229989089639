import { rejectReasonService } from '../../helpers/authservice/reject-reason.service';
import router from '../../router/index'
import Swal from 'sweetalert2';

export const state = {
    rejectReasons: [],
    pagination: {
        per_page: '',
        total: '',
        page: ''
    },
}

export const mutations = {
    FETCH_REJECT_REASONS: (state, payload) => {
        state.rejectReasons = payload;
    },
    FETCH_PAGINATION: (state, payload) => {
        state.pagination = payload;
    }
}

export const actions = {
    getRejectReasons: ({ commit, dispatch }, pagination) => {
        dispatch('globals/loading', null, { root: true })

        rejectReasonService.getAll(pagination)
        .then(({ data, meta }) => {
            commit('FETCH_REJECT_REASONS', data);
            commit('FETCH_PAGINATION', {
                page: meta.current_page,
                total: meta.total,
                per_page: meta.per_page
            });
            dispatch('globals/loaded', null, { root: true })
        })
        .catch((error) => {
            const { message } = error.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true })
        })
    },
    createRejectReason: ({ dispatch }, body) => {
        dispatch('globals/loading', null, { root: true })
        rejectReasonService.create(body)
        .then(() => {
            dispatch('notification/clear', null, { root: true });
            router.push('/settings/reject-reasons/list');
            Swal.fire("Success", `You have created a new reject reason ${body.name}`, "success");
            dispatch('globals/loaded', null, { root: true })
        })
        .catch((error) => {
            const { errors } = error.response.data;
            window.scrollTo(0, 0);
            dispatch('notification/errors', errors, { root: true });
            dispatch('globals/loaded', null, { root: true })
         })
    },
    updateRejectReason: ({ state, dispatch }, existingLocation) => {
        dispatch('globals/loading', null, { root: true });

        rejectReasonService.update(existingLocation)
        .then(() => {
            dispatch('notification/clear', null, { root: true });
            dispatch('globals/loaded', null, { root: true });
            dispatch('notification/success', 'Reject reason updated successfully', { root: true });
            dispatch('getRejectReasons', state.pagination);
        })
        .catch((err) => {
            const { error } = err.response.data;
            window.scrollTo(0, 0);
            dispatch('notification/error', error, { root: true });
            dispatch('globals/loaded', null, { root: true });
        })
    },
    deleteRejectReason: ({ state, dispatch }, id) => {
        dispatch('globals/loading', null, { root: true })

        rejectReasonService.destroy(id)
        .then(() => {
            dispatch('notification/clear', null, { root: true });
            router.push('/settings/reject-reasons/list');
            dispatch('globals/loaded', null, { root: true });
            dispatch('getRejectReasons', state.pagination);
        })
        .catch((error) => {
            const { errors } = error.response.data;
            window.scrollTo(0, 0);
            dispatch('notification/errors', errors, { root: true });
            dispatch('globals/loaded', null, { root: true });
         })
    }
}