import axios from 'axios';
import { authHeader } from './auth-header';

export const novReturnCard = {
    getAll,
    fetchDownloadableRCTemplate,
    setPrintStatus,
    setRCPrintStatus,
    setNOVPrintStatus
};

function getAll({ 
    pageSize, 
    page, 
    search, 
    orderBy, 
    sortColumn  
}) {
    return axios.get('/delivery/print', {
        params: {
            pageSize,
            page,
            search,
            orderBy,
            sortColumn
        },
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}


function fetchDownloadableRCTemplate({ batch_id, type, location}) {
    return axios.get('/delivery/print/return-cards', {
        params: {
            batch_id,
            type,
            location
        },
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function setPrintStatus({ batch_id, is_printed }) {
    return axios.post('/delivery/print/set-printed-status', null, {
        headers: authHeader(),
        params: {
            batch_id,
            is_printed
        }
    })
}

function setRCPrintStatus({ batch_id, type, location }) {
    return axios.post('/delivery/print/return-cards/set-printed-status', null, {
        headers: authHeader(),
        params: {
            batch_id,
            type,
            location
        }
    })
}

function setNOVPrintStatus({ batch_id, start, end }) {
    return axios.post('/delivery/print/novs/set-printed-status', null, {
        headers: authHeader(),
        params: {
            batch_id,
            start,
            end
        }
    })
}
