import { deliveryService } from '../../helpers/authservice/delivery.service';

export const state = {
    printManifest: [],
    printManifestPagination: {
        per_page: '',
        total: '',
        page: ''
    },
}

export const mutations = {
    FETCH_PRINT_MANIFEST: (state, payload) => {
        state.printManifest = payload;
    },
    FETCH_PRINT_MANIFEST_PAGINATION: (state, payload) => {
        state.printManifestPagination = payload;
    }
}

export const actions = {
    getAllPrintManifest: ({ commit, dispatch }, pagination) => {
        dispatch('globals/loading', null, { root: true })

        deliveryService.getAllPrintManifest(pagination)
        .then(({ data, meta }) => {
            commit('FETCH_PRINT_MANIFEST', data);
            commit('FETCH_PRINT_MANIFEST_PAGINATION', {
                page: meta.current_page,
                total: meta.total,
                per_page: meta.per_page
            });
            dispatch('globals/loaded', null, { root: true })
        })
        .catch((error) => {
            const { message } = error.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true });
        })
    },
    setPrintManifestPrintStatus: ({ state, dispatch }, { batch_id }) => {
        dispatch('globals/loading', null, { root: true });

        deliveryService.setPrintManifestPrintStatus({ batch_id  })
        .then(() => {
            dispatch('getAllPrintManifest', state.printManifestPagination);
            dispatch('globals/loaded', null, { root: true });
        })
        .catch((err) => {
            console.log(err)
            const { message } = err.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true });
        })
    },
}