import { authService } from '../../helpers/authservice/auth.service';
import router from '../../router/index';
import Swal from 'sweetalert2';

const user = JSON.parse(localStorage.getItem('user'));
const userRoles = JSON.parse(localStorage.getItem('roles'));

export const state = {
    user: user ? user : null,
    userRoles: userRoles ? userRoles : null,
    success: false,
    status: { loggeduser: user ? true : false },
}

export const getters = {
    user: (state) => state.user
}

export const actions = {
    // Logs in the user.
    login({ dispatch, commit }, { username, password }) {
        dispatch('globals/loading', null, { root: true });
        commit('loginRequest', { username });

        let loginInfo = null;

        loginInfo = {
            username,
            password
        }
        if (validateEmail(username)) {
            loginInfo = {
              email: username,
              password
            }
          }

        authService.login(loginInfo)
        .then(async ({ user }) => {
            commit('loginSuccess', user);
            const { data } = await authService.getUserRoles(user.id);
            commit('setUserRoles', data);
            dispatch('globals/loaded', null, { root: true });

            if (user.is_password_forgot === 0) {
                // return router.push('/')
                return window.location.href = '/';
            }
            
            window.localStorage.setItem('temp_password', JSON.stringify({ temp_password: password }))
            return router.push('/change-password');
        })
        .catch((error) => {
            let newMessage = '';
            const { message } = error.response.data;

            switch (message) {
                case 'Invalid credentials':
                    newMessage = 'Invalid username/email or password';
                    break;
                case 'User does not exists!':
                    newMessage = 'Account does not exist.';
                    break;
                case 'Your account is not active.':
                    newMessage = 'Sorry, your account is inactive. Please call an administrator.'
                    break;
                default:
                    newMessage = message
                    break;
            }

            commit('loginFailure', newMessage);
            dispatch('notification/error', newMessage, { root: true });
            dispatch('globals/loaded', null, { root: true });
        })
    },
    // Logout the user
    logout({ commit }) {
        authService.logout();
        commit('logout');
    },
    // register the user
    registerUser({ dispatch, commit }, user) {
        dispatch('globals/loading', null, { root: true });
        commit('registerRequest', user);
        authService.register(user)
            .then(
                user => {
                    commit('registerSuccess', user);
                    dispatch('notification/success', 'Registration successful', { root: true });
                    dispatch('globals/loaded', null, { root: true });
                    router.push('/login');
                },
                error => {
                    commit('registerFailure', error);
                    dispatch('notification/error', error, { root: true });
                    dispatch('globals/loaded', null, { root: true });
                }
            );
    },
    resetPassword: ({ commit, dispatch }, body) => {
        dispatch('globals/loading', null, { root: true });

        authService.resetPassword(body)
        .then(() => {
            commit('success', true);
            dispatch('notification/clear', null, { root: true });
            dispatch('globals/loaded', null, { root: true });
        })
        .catch((error) => {
            const { message } = error.response.data;
            commit('success', false);
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true });
        })
    },
    changePassword: ({ commit, dispatch }, body) => {
        dispatch('globals/loading', null, { root: true });

        const user = JSON.parse(localStorage.getItem('user'));

        if (user && user.is_password_forgot === 1) {
            delete body.old_password
        } else {
            delete body.temporary_password
        }

        authService.changePassword(body)
        .then((data) => {
            commit('passwordChangeSuccess', data);
            localStorage.setItem('user', JSON.stringify(data.user));
            localStorage.removeItem('temp_password');
            dispatch('notification/clear', null, { root: true });
            dispatch('globals/loaded', null, { root: true });

            Swal.fire(
                "Success", 
                "You have successfully changed your password!", 
                "success").then((result) => {
                    if (result.value) {
                        window.location.href = '/';
                    }
                });

        })
        .catch((error) => {
            let newMessage = '';
            const message  = error.response.data.errors;
            message.forEach(item => {
                newMessage += item + " ";
            });
            dispatch('notification/error', newMessage, { root: true });
            dispatch('globals/loaded', null, { root: true });
        })
    }
};

export const mutations = {
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = { loggeduser: true };
        state.user = user;
    },
    passwordChangeSuccess(state, user) {
        state.status = { loggeduser: true };
        state.user = user;
    },
    passwordChangeFailure(state) {
        state.status = {};
        state.user = null;
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.user = null;
    },
    registerRequest(state) {
        state.status = { registering: true };
    },
    registerSuccess(state) {
        state.status = {};
    },
    registerFailure(state) {
        state.status = {};
    },
    success(state, successBoolean) {
        state.success = successBoolean;
    },
    setUserRoles(state, payload) {
        state.userRoles = payload;
    }
};

const validateEmail = (email) => {
    // eslint-disable-next-line
    return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
}

