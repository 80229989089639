export const state = {
    type: null,
    message: null,
    messages: []
};

export const mutations = {
    success(state, message) {
        state.type = 'success';
        state.message = message;
    },
    error(state, message) {
        state.type = 'danger';
        state.message = message;
    },
    clear(state) {
        state.type = null;
        state.message = null;
        state.messages = [];
    },
    errors(state, messages) {
        state.type = 'danger';
        state.messages = messages;
    }
};

export const actions = {
    success({ commit }, message) {
        commit('clear');
        commit('success', message);
    },
    error({ commit }, message) {
        commit('clear');
        commit('error', message);
    },
    clear({ commit }) {
        commit('clear');
    },
    errors({ commit }, messages) {
        commit('clear');
        commit('errors', messages);
    }
};
