import axios from 'axios';
import { authHeader } from './auth-header';

export const userService = {
    getAll,
    getOne,
    getUserRoles,
    create,
    updateUser,
    deleteUser,
    updatePassword,
    activateUser,
    deactivateUser,
    updateUserLGUAccess,
    updateMode
};

function getAll({ size, search, sortColumn, orderBy, page }) {
    return axios.get('/users', {
        params: {
            pageSize: size,
            sortColumn,
            orderBy,
            page,
            search
        },
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function getOne(id) {
    return axios.get(`/users/${id}`, {
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function getUserRoles(id) {
    return axios.get(`/users/${id}/roles`, {
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function create(data) {
    return axios.post('/register', data, {
        headers: { ...authHeader(), 'Content-Type': 'multipart/form-data' }
    })
    .then(({ data }) => {
        return data;
    })
}

function updateUser(data, id) {
    return axios.post(`users/${id}`, data, {
        params: {
            _method: 'PATCH'
        },
        headers: { ...authHeader(), 'Content-Type': 'multipart/form-data' }
    })
    .then(({ data }) => {
        return data;
    })
}

function deleteUser(id) {
    return axios.delete(`users/${id}`, {
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function updatePassword(data) {
    return axios.put(`users/${data.id}`, data)
    .then(({ data }) => {
        return data;
    })
}

function activateUser(id) {
    return axios.post(`users/activate/${id}`, null, {
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function deactivateUser(id) {
    return axios.post(`users/deactivate/${id}`, null, {
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function updateUserLGUAccess({ user_id, lgus, roles }) {
    const data = { lgus, roles }

    return axios.put(`/users/${user_id}`, data, {
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function updateMode(data) {
    console.log('data', data);
    return axios.patch(`users/${data.id}/mode`, {mode: data.mode})
    .then(({ data }) => {
        return data;
    })
}
