import { trackingService } from '../../helpers/authservice/tracking.service';
import Swal from 'sweetalert2';

export const state = {
    batchNumberNovs: [],
    trackingBarcodeLogs: null,
    trackingDeliveryInfo: null,
    dropdownTrackingStatuses: [],
    dropdownTrackingStatusReasons: []
}

export const getters = {
    batchNumberNovs: (state) => state.batchNumberNovs,
    trackingBarcodeLogs: (state) => state.trackingBarcodeLogs,
    trackingDeliveryInfo: (state) => state.trackingDeliveryInfo
}

export const mutations = {
    FETCH_BATCH_NUMBER_NOVS: (state, payload) => {
        state.batchNumberNovs = payload;
    },
    FETCH_TRACKING_BARCODE_LOGS: (state, payload) => {
        state.trackingBarcodeLogs = payload;
    },
    FETCH_TRACKING_DELIVERY_INFO: (state, payload) => {
        state.trackingDeliveryInfo = payload;
    },
    FETCH_DROPDOWN_TRACKING_STATUSES: (state, payload) => {
        state.dropdownTrackingStatuses = payload;
    },
    FETCH_DROPDOWN_TRACKING_STATUS_REASONS: (state, payload) => {
        state.dropdownTrackingStatusReasons = payload;
    }
}

export const actions = {
    getAllBatchNumberNovs({ commit, dispatch }, search) {
        dispatch('globals/loading', null, { root: true });

        trackingService.searchBatchNumber(search)
        .then(({ data }) => {
            dispatch('notification/clear', null, { root: true });
            commit('FETCH_BATCH_NUMBER_NOVS', data);
            dispatch('globals/loaded', null, { root: true })
        })
        .catch((error) => {
            commit('FETCH_BATCH_NUMBER_NOVS', []);
            dispatch('notification/clear', null, { root: true });
            const { message } = error.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true })
        })
    },
    updateTrackingEntries({ commit, dispatch }, batch_number) {
        dispatch('globals/loading', null, { root: true });

        trackingService.updateTrackingEntries(batch_number)
        .then(({ data }) => {
            commit('FETCH_BATCH_NUMBER_NOVS', data);
            dispatch('globals/loaded', null, { root: true })
            dispatch('notification/clear', null, { root: true })
        })
        .catch((error) => {
            dispatch('globals/loaded', null, { root: true })
            const { message } = error.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true })
        })
    },
    getAllTrackingEntriesByScanBarcode({ commit, dispatch }, search) {
        dispatch('globals/loading', null, { root: true });
        commit('FETCH_TRACKING_BARCODE_LOGS', null);

        trackingService.scanBarcodeTrackingEntries(search)
        .then(({ data }) => {
            dispatch('notification/clear', null, { root: true });
            commit('FETCH_TRACKING_BARCODE_LOGS', data);
            dispatch('globals/loaded', null, { root: true })
        })
        .catch((error) => {
            commit('FETCH_TRACKING_BARCODE_LOGS', null);
            dispatch('notification/clear', null, { root: true });
            const { message } = error.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true })
        })
    },
    getAllTrackingEntriesByDeliveryInfo({ commit, dispatch }, search) {
        dispatch('globals/loading', null, { root: true });
        commit('FETCH_TRACKING_DELIVERY_INFO', null);

        trackingService.scanBarcodeDeliveryInfo(search)
        .then(({ data }) => {
            dispatch('notification/clear', null, { root: true });
            commit('FETCH_TRACKING_DELIVERY_INFO', data);
            dispatch('globals/loaded', null, { root: true })
        })
        .catch((error) => {
            commit('FETCH_TRACKING_DELIVERY_INFO', null);
            dispatch('notification/clear', null, { root: true });
            const { message } = error.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true })
        })
    },
    getDropdownTrackingStatuses({ commit, dispatch }) {
        dispatch('globals/loading', null, { root: true });

        trackingService.getDropdownTrackingStatuses()
        .then(({ data }) => {
            commit('FETCH_DROPDOWN_TRACKING_STATUSES', data);
            dispatch('globals/loaded', null, { root: true });
        })
        .catch((error) => {
            const { message } = error.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true });
        })
    },
    getDropdownTrackingStatusReasons({ commit, dispatch }) {
        dispatch('globals/loading', null, { root: true });

        trackingService.getDropdownTrackingStatusReasons()
        .then(({ data }) => {
            commit('FETCH_DROPDOWN_TRACKING_STATUS_REASONS', data);
            dispatch('globals/loaded', null, { root: true });
        })
        .catch((error) => {
            const { message } = error.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true });
        })
    },
    updateDeliveryInfo: ({ commit, dispatch }, deliveryInfo) => {
        dispatch('globals/loading', null, { root: true })

        trackingService.updateDeliveryInfo(deliveryInfo)
        .then(({ data }) => {
            window.scrollTo(0, 0);
            dispatch('notification/success', 'Tracking updated', { root: true });
            commit('FETCH_TRACKING_DELIVERY_INFO', data);
            Swal.fire(
                "Updated", 
                `Successfully updated the delivery information`, 
                "success"
            );
            dispatch('globals/loaded', null, { root: true });
        })
        .catch((error) => {
           const { errors } = error.response.data;
           window.scrollTo(0, 0);
           dispatch('notification/errors', errors, { root: true });
           dispatch('globals/loaded', null, { root: true })
        })
    },
}