<script setup>
import { onMounted } from 'vue';
import store from './state/store';

onMounted(() => {
  if (process.env.VUE_APP_MAINTENANCE && process.env.VUE_APP_MAINTENANCE === 'true') {
    store.dispatch('auth/logout');
  }
})
</script>

<template>
  <router-view></router-view>
</template>
