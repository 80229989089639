import axios from 'axios';
import { authHeader } from './auth-header';

export const violationTypeService = {
    getAll,
    create,
    update,
    destroy
};

function getAll({ 
    pageSize, 
    page, 
    search, 
    orderBy, 
    sortColumn  
}) {
    return axios.get('/violation', {
        params: {
            pageSize,
            page,
            search,
            orderBy,
            sortColumn
        },
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function create(data) {
    return axios.post('/violation', data, {
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function update(data) {
    return axios.put(`/violation/${data.id}`, data, {
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function destroy(id) {
    return axios.delete(`/violation/${id}`, {
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}