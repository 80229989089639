require("dotenv").config();
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import AOS from "aos";
import "aos/dist/aos.css";
import i18n from "./i18n";

import store from "./state/store";
import eventbus from "@jakriese/vue3-event-bus";

import BootstrapVue3 from "bootstrap-vue-3";
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import Maska from "maska";
import axios from "axios";

import MtIdleTimer from "mt-idle-timer";
// import Vidle from "v-idle-3";

import VueFeather from "vue-feather";
import Particles from "particles.vue3";

import "@/assets/scss/config/material/app.scss";
import "@vueform/slider/themes/default.css";

axios.defaults.baseURL =
  process.env.VUE_APP_BASE_URL || "http://uat.tcms-main-backend.qpax.org/api";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const { status, config } = error.response;

    const publicURLs = ["/login", "/change_password", "/forgot_password", '/void'];

    if (status === 401 && publicURLs.includes(config.url) !== true) {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      location.reload();
    }
    return Promise.reject(error);
  }
);

AOS.init({
  easing: "ease-out-back",
  duration: 1000,
});

createApp(App)
  .use(store)
  .use(router)
  .use(VueApexCharts)
  .use(BootstrapVue3)
  .component(VueFeather.type, VueFeather)
  .use(Maska)
  .use(Particles)
  .use(i18n)
  .use(vClickOutside)
  .use(eventbus)
  .use(MtIdleTimer)
  .mount("#app");
