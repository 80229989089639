import { roleService } from '../../helpers/authservice/role.service';
import Swal from 'sweetalert2';
import router from '../../router/index'

export const state = {
    roles: [],
    dropdownRoles: [],
    newRole: null,
    pagination: {
        per_page: '',
        total: '',
        page: ''
    }
}

export const getters = {
    roles: (state) => state.roles,
}

export const mutations = {
    FETCH_ROLES: (state, payload) => {
        state.roles = payload;
    },
    FETCH_DROPDOWN_ROLES: (state, payload) => {
        state.dropdownRoles = payload;
    },
    FETCH_PAGINATION: (state, payload) => {
        state.pagination = payload;
    },
    ADD_ROLE: (state, payload) => {
        state.roles.push(payload);
        state.dropdownRoles.push(payload);
    }
}

export const actions = {
    getDropdownRoles: ({ commit, dispatch }) => {
        dispatch('globals/loading', null, { root: true });

        roleService.getAllDropdown()
        .then(({ data }) => {
            commit('FETCH_DROPDOWN_ROLES', data);
            dispatch('globals/loaded', null, { root: true });
        })
        .catch((error) => {
            const { message } = error.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true });
        })
    },
    getRoles: ({ commit, dispatch }, pagination) => {
        dispatch('globals/loading', null, { root: true });

        roleService.getAll(pagination)
        .then(({ data, meta }) => {
            commit('FETCH_PAGINATION', {
                page: meta.current_page,
                total: meta.total,
                per_page: meta.per_page
            })
            commit('FETCH_ROLES', data);
            dispatch('globals/loaded', null, { root: true });
        })
        .catch((error) => {
            const { message } = error.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true });
        })
    },
    createRole: ({ dispatch }, body) => {
        dispatch('globals/loading', null, { root: true })

        roleService.create(body)
        .then(() => {
            dispatch('notification/clear', null, { root: true });
            router.push('/user-management/roles/list');
            Swal.fire("Success", `You have created a new role ${body.name}` , "success");
            dispatch('globals/loaded', null, { root: true })
        })
        .catch((error) => {
            let errorsArray = [];
            const { errors } = error.response.data;
            for (error in errors) {
                errorsArray = [ ...errorsArray, errors[error][0]]
            }
            window.scrollTo(0, 0);
            dispatch('notification/errors', errorsArray, { root: true });
            dispatch('globals/loaded', null, { root: true })
         })
    },
    updateRole: ({ dispatch }, body) => {
        dispatch('globals/loading', null, { root: true })

        roleService.update(body)
        .then(() => {
            window.scrollTo(0, 0);
            dispatch('notification/success', 'Role updated successfully', { root: true });
            dispatch('globals/loaded', null, { root: true });
        })
        .catch((error) => {
           const { errors } = error.response.data;
           window.scrollTo(0, 0);
           dispatch('notification/errors', errors, { root: true });
           dispatch('globals/loaded', null, { root: true })
        })
    },
    deleteRole: ({ state, dispatch }, { id, name }) => {
        dispatch('globals/loading', null, { root: true })

        roleService.destroy(id)
        .then(() => {
            dispatch('notification/clear', null, { root: true });
            router.push('/user-management/roles/list');
            dispatch('globals/loaded', null, { root: true })
            Swal.fire("Deleted!", `Role ${name} has been successfully deleted!`, "success");
            dispatch('getRoles', state.pagination);
        })
        .catch((error) => {
            const { errors } = error.response.data;
            window.scrollTo(0, 0);
            dispatch('notification/errors', errors, { root: true });
            dispatch('globals/loaded', null, { root: true })
         })
    },
    updatePermissions: ({ dispatch }, body) => {
        dispatch('globals/loading', null, { root: true })

        roleService.updatePermissions(body)
        .then(() => {
            dispatch('notification/clear', null, { root: true });
            window.scrollTo(0, 0);
            Swal.fire("Successfully!", `Permissions updated! Please inform the users that uses this role to re-login again.`, "success");
            dispatch('globals/loaded', null, { root: true });
        })
        .catch((error) => {
           const { errors } = error.response.data;
           window.scrollTo(0, 0);
           dispatch('notification/errors', errors, { root: true });
           dispatch('globals/loaded', null, { root: true })
        })
    },
}