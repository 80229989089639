import { violationTypeService } from '../../helpers/authservice/violation-type.service';
import router from '../../router/index'
import Swal from 'sweetalert2';

export const state = {
    violationTypes: [],
    pagination: {
        per_page: '',
        total: '',
        page: ''
    },
}

export const mutations = {
    FETCH_VIOLATION_TYPES: (state, payload) => {
        state.violationTypes = payload;
    },
    FETCH_PAGINATION: (state, payload) => {
        state.pagination = payload;
    }
}

export const actions = {
    getViolationTypes: ({ commit, dispatch }, pagination) => {
        dispatch('globals/loading', null, { root: true })

        violationTypeService.getAll(pagination)
        .then(({ data, meta }) => {
            commit('FETCH_VIOLATION_TYPES', data);
            commit('FETCH_PAGINATION', {
                page: meta.current_page,
                total: meta.total,
                per_page: meta.per_page
            });
            dispatch('globals/loaded', null, { root: true })
        })
        .catch((error) => {
            const { message } = error.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true })
        })
    },
    createViolationType: ({ dispatch }, body) => {
        dispatch('globals/loading', null, { root: true })

        const newViolationType = { 
            ...body, 
            status: body.status ? 1 : 0,
            base: body.base ? 1 : 0 
        }

        violationTypeService.create(newViolationType)
        .then(() => {
            dispatch('notification/clear', null, { root: true });
            router.push('/settings/violation-types/list');
            Swal.fire("Success", `You have created a new violation type ${newViolationType.name}` , "success");
            dispatch('globals/loaded', null, { root: true })
        })
        .catch((error) => {
            const { errors } = error.response.data;
            window.scrollTo(0, 0);
            dispatch('notification/errors', errors, { root: true });
            dispatch('globals/loaded', null, { root: true })
         })
    },
    updateViolationType: ({ dispatch }, body) => {
        dispatch('globals/loading', null, { root: true })

        const existingViolationType = { 
            ...body, 
            status: body.status ? 1 : 0,
            base: body.base ? 1 : 0 
        }
        console.log(existingViolationType);

        violationTypeService.update(existingViolationType)
        .then(() => {
            window.scrollTo(0, 0);
            dispatch('notification/success', 'Violation type updated successfully', { root: true });
            dispatch('globals/loaded', null, { root: true })
        })
        .catch((error) => {
           const { errors } = error.response.data;
           window.scrollTo(0, 0);
           dispatch('notification/errors', errors, { root: true });
           dispatch('globals/loaded', null, { root: true })
        })
    },
    deleteViolationType: ({ state, dispatch }, id) => {
        dispatch('globals/loading', null, { root: true })

        violationTypeService.destroy(id)
        .then(() => {
            dispatch('notification/clear', null, { root: true });
            router.push('/settings/violation-types/list');
            dispatch('globals/loaded', null, { root: true });
            dispatch('getViolationTypes', state.pagination);
           
        })
        .catch((error) => {
            const { errors } = error.response.data;
            window.scrollTo(0, 0);
            dispatch('notification/errors', errors, { root: true });
            dispatch('globals/loaded', null, { root: true });
         })
    }
}