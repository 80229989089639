import store from "@/state/store";

const userRoles = JSON.parse(localStorage.getItem('roles'));

const roleExist = (slug) => {
  if (userRoles && userRoles.length) {
      return userRoles.some((obj) => (obj.slug === slug));
  }

  return false;
}

export default [
  {
    path: "/:pathMatch(.*)*",
    name: 'error-404',
    meta: {
      title: "Error 404", authRequired: true,
    },
    component: () => import('../views/auth/errors/404-basic')
  },
  {
    path: "/login",
    name: "login",
    component: () => process.env.VUE_APP_MAINTENANCE && process.env.VUE_APP_MAINTENANCE === 'true' ? import("../views/pages/maintenance.vue") : import("../views/account/login.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/user']) next()
      next({ name: "default" });
    },
    meta:  { title: "Login" }
  },
  {
    path: "/maintenance",
    name: 'maintenance',
    meta: {
      title: "Maintenance", authRequired: false,
    },
    component: () => import('../views/pages/maintenance')
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: { title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/user']) next()

      next({ name: "default" });
    },
    meta: { title: "Forgot Password" }
  },
  {
    path: '/change-password',
    name: 'change-password',
    meta: {
      title: "Change Password", authRequired: true
    },
    component: () => import('../views/account/change-password')
  },
  {
    path: "/",
    name: "default",
    beforeEnter: (to, from, next) => {
      if (roleExist('dashboard')) {
        next();
      }
      next({ name: 'home' })
    },
    meta: {
      title: "Dashboard",
      authRequired: true,
    },
    component: () => import("../views/dashboard/index.vue"),
  },
  {
    path: "/home",
    name: "home",
    meta: {
      title: "Home",
      authRequired: true,
    },
    component: () => import("../views/dashboard/home.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout", authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch("auth/logout");

        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute ? { name: "default" } : { ...routeFrom }
        );
      },
    },
    component: () => import("../views/auth/logout/basic")
  },
  {
    path: '/auth/500',
    name: '500',
    meta: {
      title: "Error 500", authRequired: true,
    },
    component: () => import('../views/auth/errors/500')
  },
  {
    path: '/forecast/financial-forecast',
    name: 'financial-forecast',
    beforeEnter: (to, from, next) => {
      if (roleExist('financial-forecast')) {
         next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Forecast - Financial Forecast", authRequired: true,
    },
    component: () => import('../views/forecast/financial-forecast')
  },
  {
    path: '/forecast/alarm-efficiency',
    name: 'alarm-efficiency',
    beforeEnter: (to, from, next) => {
      if (roleExist('alarm-efficiency')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Forecast - Alarm Efficiency", authRequired: true,
    },
    component: () => import('../views/forecast/alarm-efficiency')
  },
  {
    path: '/search/general-search',
    name: 'general-search',
    beforeEnter: (to, from, next) => {
      if (roleExist('void') || roleExist('search-and-view') || roleExist('edit-record') ||
            roleExist('resend-to-lto') || roleExist('edit-mv-file-records') || roleExist('edit-violation')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Search - General Search", authRequired: true,
    },
    component: () => import('../views/search/general-search')
  },
  {
    path: '/traffic-records/input-plate-no',
    name: 'input-plate-no',
    beforeEnter: (to, from, next) => {
      if (roleExist('input-plate-no')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Traffic Records - Input Plate Number", authRequired: true,
    },
    component: () => import('../views/traffic-records/input-plate-no')
  },
  {
    path: '/traffic-records/user-monitoring',
    name: 'user-monitoring',
    beforeEnter: (to, from, next) => {
      if (roleExist('user-monitoring')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Traffic Records - User Monitoring", authRequired: true,
    },
    component: () => import('../views/traffic-records/user-monitoring')
  },
  {
    path: '/traffic-records/verify-validate',
    name: 'verify-traffic-record',
    beforeEnter: (to, from, next) => {
      if (roleExist('verify-traffic-record') || roleExist('validate')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Traffic Records - Verify-Validate", authRequired: true,
    },
    component: () => import('../views/traffic-records/validate')
  },
  {
    path: '/traffic-records/lgu-approval',
    name: 'lgu-approval',
    beforeEnter: (to, from, next) => {
      if (roleExist('approval')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Traffic Records - LGU Approval", authRequired: true,
    },
    component: () => import('../views/traffic-records/lgu-approval')
  },
  {
    path: '/traffic-records/dispute',
    name: 'dispute',
    beforeEnter: (to, from, next) => {
      if (roleExist('dispute')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Traffic Records - Dispute", authRequired: true,
    },
    component: () => import('../views/traffic-records/dispute')
  },
  {
    path: '/traffic-records/rejected-record',
    name: 'rejected-record',
    beforeEnter: (to, from, next) => {
      if (roleExist('rejected-record')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Traffic Records - Rejected Record", authRequired: true,
    },
    component: () => import('../views/traffic-records/rejected-record')
  },
  {
    path: '/traffic-records/reporting',
    name: 'reporting',
    beforeEnter: (to, from, next) => {
      if (roleExist('reporting')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Traffic Records - Reporting", authRequired: true,
    },
    component: () => import('../views/traffic-records/reporting')
  },
  {
    path: '/traffic-records/bulk-void',
    name: 'bulk-void',
    beforeEnter: (to, from, next) => {
      if (roleExist('bulk-void')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Traffic Records - Bulk Void", authRequired: true,
    },
    component: () => import('../views/traffic-records/bulk-void')
  },
  {
    path: '/lgu/approval',
    name: 'approval',
    beforeEnter: (to, from, next) => {
      if (roleExist('approval')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "LGU - Approval", authRequired: true,
    },
    component: () => import('../views/lgu/approval')
  },
  {
    path: '/ovr/view-batch',
    name: 'view-batch',
    beforeEnter: (to, from, next) => {
      if (roleExist('edit-manifest')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "OVR - View Batch", authRequired: true,
    },
    component: () => import('../views/ovr/view-batch')
  },
  {
    path: '/ovr/print-ovr',
    name: 'print-ovr',
    beforeEnter: (to, from, next) => {
      if (roleExist('print-ovr')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "OVR - Print OVR (Dot Matrix)", authRequired: true,
    },
    component: () => import('../views/ovr/print-ovr')
  },
  {
    path: '/ovr/edit-ovr',
    name: 'edit-ovr',
    beforeEnter: (to, from, next) => {
      if (roleExist('edit-ovr')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "OVR - Edit OVR", authRequired: true,
    },
    component: () => import('../views/ovr/edit-ovr')
  },
  {
    path: '/ovr/batch-report',
    name: 'batch-report',
    beforeEnter: (to, from, next) => {
      if (roleExist('batch-report')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "OVR - Batch Report", authRequired: true,
    },
    component: () => import('../views/ovr/batch-report')
  },
  {
    path: '/nov/search-nov',
    name: 'search-nov',
    beforeEnter: (to, from, next) => {
      if (roleExist('search-nov')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "NOV - Search NOV", authRequired: true,
    },
    component: () => import('../views/nov/search-nov')
  },
  {
    path: '/payment/order',
    name: 'order-of-payment',
    beforeEnter: (to, from, next) => {
      if (roleExist('order-of-payment')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Payment - Order of Payment", authRequired: true,
    },
    component: () => import('../views/payment/order-of-payment')
  },
  {
    path: '/payment/official-receipt',
    name: 'official-receipt',
    beforeEnter: (to, from, next) => {
      if (roleExist('official-receipt')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Payment - Official Receipt", authRequired: true,
    },
    component: () => import('../views/payment/official-receipt')
  },
  {
    path: '/payment/report',
    name: 'report',
    beforeEnter: (to, from, next) => {
      if (roleExist('report')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Payment - Report", authRequired: true,
    },
    component: () => import('../views/payment/report')
  },
  {
    path: '/payment/recently-paid',
    name: 'recently-paid',
    beforeEnter: (to, from, next) => {
      if (roleExist('recently-paid')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Payment - Recently Paid", authRequired: true,
    },
    component: () => import('../views/payment/recently-paid')
  },
  {
    path: '/payment/ucpb-report',
    name: 'ucpb-report',
    beforeEnter: (to, from, next) => {
      if (roleExist('ucpb-report')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Payment - UCPB Report", authRequired: true,
    },
    component: () => import('../views/payment/ucpb-report')
  },
  {
    path: '/payment/edit-or',
    name: 'edit-or',
    beforeEnter: (to, from, next) => {
      if (roleExist('edit-or')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Payment - Edit OR", authRequired: true,
    },
    component: () => import('../views/payment/edit-or')
  },
  {
    path: '/delivery/print-nov',
    name: 'print-nov',
    beforeEnter: (to, from, next) => {
      if (roleExist('print-novs-return-card')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Delivery - Print NOV (Return Card)", authRequired: true,
    },
    component: () => import('../views/delivery/print-nov')
  },
  {
    path: '/delivery/add-tracking',
    name: 'add-tracking-number',
    beforeEnter: (to, from, next) => {
      if (roleExist('add-tracking')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Delivery - Add Tracking", authRequired: true,
    },
    component: () => import('../views/delivery/add-tracking')
  },
  {
    path: '/delivery/couriers/list',
    name: 'couriers-list',
    beforeEnter: (to, from, next) => {
      if (roleExist('add-tracking')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Delivery - Couriers List", authRequired: true,
    },
    component: () => import('../views/delivery/courier/list.vue')
  },
  {
    path: '/delivery/couriers/create',
    name: 'couriers-create',
    beforeEnter: (to, from, next) => {
      if (roleExist('add-tracking')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Delivery - Create Courier", authRequired: true,
    },
    component: () => import('../views/delivery/courier/create.vue')
  },
  {
    path: '/delivery/print-manifest',
    name: 'print-manifest',
    beforeEnter: (to, from, next) => {
      if (roleExist('print-manifest')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Delivery - Print Manifest", authRequired: true,
    },
    component: () => import('../views/delivery/print-manifest')
  },
  {
    path: '/delivery/report',
    name: 'delivery-report',
    beforeEnter: (to, from, next) => {
      if (roleExist('delivery-report')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Delivery - Report", authRequired: true,
    },
    component: () => import('../views/delivery/delivery-report')
  },
  {
    path: '/delivery/entry-report',
    name: 'delivery-entry-report',
    beforeEnter: (to, from, next) => {
      if (roleExist('delivery-entry-report')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Delivery - Entry Report", authRequired: true,
    },
    component: () => import('../views/delivery/delivery-entry-report')
  },
  {
    path: '/tracking/batch-received-couriers',
    name: 'batch-received-couriers',
    beforeEnter: (to, from, next) => {
      if (roleExist('batch-received-by-philpost')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Tracking - Batch Received by Couriers", authRequired: true,
    },
    component: () => import('../views/tracking/batch-received-by-philpost')
  },
  {
    path: '/tracking/delivered',
    name: 'delivered-returned-rts',
    beforeEnter: (to, from, next) => {
      if (roleExist('delivered-returned-rts')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Tracking - Delivered/Returned (RTS)", authRequired: true,
    },
    component: () => import('../views/tracking/delivered-returned-rts')
  },
  {
    path: '/tracking/delivery-info',
    name: 'update-delivery-info',
    beforeEnter: (to, from, next) => {
      if (roleExist('update-delivery-info')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Tracking - Update Delivery Info", authRequired: true,
    },
    component: () => import('../views/tracking/update-delivery-info')
  },
  {
    path: '/tracking/correction',
    name: 'correction',
    beforeEnter: (to, from, next) => {
      if (roleExist('correction')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Tracking - Correction", authRequired: true,
    },
    component: () => import('../views/tracking/correction')
  },
  {
    path: '/apprehension/mv-records',
    name: 'mv-records',
    meta: {
      title: "Apprehension - MV Records", authRequired: true,
    },
    component: () => import('../views/apprehension/mv-records')
  },
  {
    path: '/apprehension/upload-lift',
    name: 'upload-lift',
    beforeEnter: (to, from, next) => {
      if (roleExist('manual-upload-mv')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Apprehension - Upload Lift", authRequired: true,
    },
    component: () => import('../views/apprehension/upload-lift')
  },
  {
    path: '/apprehension/api-logs',
    name: 'api-logs',
    beforeEnter: (to, from, next) => {
      if (roleExist('api-logs')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Apprehension - API Logs", authRequired: true,
    },
    component: () => import('../views/apprehension/api-logs')
  },
  {
    path: '/apprehension/report',
    name: 'apprehension-report',
    beforeEnter: (to, from, next) => {
      if (roleExist('apprehension-report')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Apprehension - Report", authRequired: true,
    },
    component: () => import('../views/apprehension/apprehension-report')
  },
  {
    path: '/user-management/users/list',
    name: 'users-list',
    beforeEnter: (to, from, next) => {
      if (roleExist('list-user')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "User Management - User List", authRequired: true,
    },
    component: () => import('../views/user-management/users/list')
  },
  {
    path: '/user-management/users/create',
    name: 'user-list-create',
    beforeEnter: (to, from, next) => {
      if (roleExist('add-user')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "User Management - Add New User", authRequired: true,
    },
    component: () => import('../views/user-management/users/create')
  },
  {
    path: '/user-management/users/:id/view',
    name: 'user-list-view',
    beforeEnter: (to, from, next) => {
      if (roleExist('view-profile')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "User Management - View User", authRequired: true,
    },
    component: () => import('../views/user-management/users/_id/view')
  },
  {
    path: '/user-management/users/:id/edit',
    name: 'user-list-edit',
    beforeEnter: (to, from, next) => {
      if (roleExist('edit-user')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "User Management - Edit User", authRequired: true,
    },
    component: () => import('../views/user-management/users/_id/edit')
  },
  {
    path: '/user-management/generate-temporary-password',
    name: 'generate-temporary-password',
    beforeEnter: (to, from, next) => {
      if (roleExist('add-user')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "User Management - Generate Temporary Password", authRequired: true,
    },
    component: () => import('../views/user-management/generate-temporary-password')
  },
  {
    path: '/user-management/roles/list',
    name: 'roles-and-permissions-list',
    beforeEnter: (to, from, next) => {
      if (roleExist('add-role') || roleExist('edit-role')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "User Management - Roles and Permissions", authRequired: true,
    },
    component: () => import('../views/user-management/roles-and-permissions/list')
  },
  {
    path: '/user-management/roles/create',
    name: 'roles-and-permissions-create',
    beforeEnter: (to, from, next) => {
      if (roleExist('add-role')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "User Management - Create Role", authRequired: true,
    },
    component: () => import('../views/user-management/roles-and-permissions/create')
  },
  {
    path: '/user-management/roles/:id/settings',
    name: 'roles-and-permissions-settings',
    beforeEnter: (to, from, next) => {
      if (roleExist('edit-role')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "User Management - Role and Permission Settings", authRequired: true,
    },
    component: () => import('../views/user-management/roles-and-permissions/_id/settings')
  },
  {
    path: '/user-management/lgu-access',
    name: 'lgu-access',
    beforeEnter: (to, from, next) => {
      if (roleExist('lgu-access')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "User Management - LGU Access", authRequired: true,
    },
    component: () => import('../views/user-management/lgu-access')
  },
  {
    path: '/user-management/user-report',
    name: 'user-report',
    beforeEnter: (to, from, next) => {
      if (roleExist('user-report')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "User Management - User Report", authRequired: true,
    },
    component: () => import('../views/user-management/user-report')
  },
  {
    path: '/user-management/audit-trail',
    name: 'audit-trail',
    beforeEnter: (to, from, next) => {
      if (roleExist('audit-trail')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "User Management - Audit Trail", authRequired: true,
    },
    component: () => import('../views/user-management/audit-trail')
  },
  {
    path: '/accounting/lto-report',
    name: 'lto-report',
    beforeEnter: (to, from, next) => {
      if (roleExist('lto-report')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Accounting - LTO Report", authRequired: true,
    },
    component: () => import('../views/accounting/lto-report')
  },
  {
    path: '/accounting/payment-report',
    name: 'payment-report',
    beforeEnter: (to, from, next) => {
      if (roleExist('payment-report')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Accounting - Payment Report", authRequired: true,
    },
    component: () => import('../views/accounting/payment-report')
  },
  {
    path: '/accounting/violation-report',
    name: 'accounting-violation-report',
    beforeEnter: (to, from, next) => {
      if (roleExist('violation-report')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Accounting - Violation Report", authRequired: true,
    },
    component: () => import('../views/accounting/violation-report')
  },
  {
    path: '/accounting/lgu-approval-report',
    name: 'lgu-approval-report',
    beforeEnter: (to, from, next) => {
      if (roleExist('lgu-approval-report')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Accounting - LGU Approval Report", authRequired: true,
    },
    component: () => import('../views/accounting/lgu-approval-report')
  },
  {
    path: '/camera/dashboard/list',
    name: 'camera-dashboard-list',
    beforeEnter: (to, from, next) => {
      if (roleExist('camera-dashboard')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Camera - Dashboard", authRequired: true,
    },
    component: () => import('../views/camera/dashboard/list')
  },
  {
    path: '/camera/dashboard/create',
    name: 'camera-dashboard-create',
    beforeEnter: (to, from, next) => {
      if (roleExist('camera-dashboard')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Camera - Create Camera", authRequired: true,
    },
    component: () => import('../views/camera/dashboard/create')
  },
  {
    path: '/camera/dashboard/:id/edit',
    name: 'camera-dashboard-edit',
    beforeEnter: (to, from, next) => {
      if (roleExist('camera-dashboard')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Camera - Edit Camera", authRequired: true,
    },
    component: () => import('../views/camera/dashboard/_id/edit')
  },
  {
    path: '/camera/type/list',
    name: 'camera-type-list',
    beforeEnter: (to, from, next) => {
      if (roleExist('camera-type')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Camera - List of Camera Types", authRequired: true,
    },
    component: () => import('../views/camera/type/list')
  },
  {
    path: '/camera/type/create',
    name: 'camera-type-create',
    beforeEnter: (to, from, next) => {
      if (roleExist('camera-type')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Camera - Create Camera Type", authRequired: true,
    },
    component: () => import('../views/camera/type/create')
  },
  {
    path: '/camera/process',
    name: 'camera-process',
    beforeEnter: (to, from, next) => {
      if (roleExist('camera-process')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Camera - Process", authRequired: true,
    },
    component: () => import('../views/camera/camera-process')
  },
  {
    path: '/camera/sort/list',
    name: 'camera-sort-list',
    beforeEnter: (to, from, next) => {
      if (roleExist('camera-sort')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Camera - Sort", authRequired: true,
    },
    component: () => import('../views/camera/sort/list')
  },
  {
    path: '/camera/locations/list',
    name: 'camera-locations-list',
    beforeEnter: (to, from, next) => {
      if (roleExist('camera-location')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Camera - Locations", authRequired: true,
    },
    component: () => import('../views/camera/locations/list')
  },
  {
    path: '/camera/locations/create',
    name: 'camera-locations-create',
    beforeEnter: (to, from, next) => {
      if (roleExist('camera-location')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Camera - Create Camera Location", authRequired: true,
    },
    component: () => import('../views/camera/locations/create')
  },
  {
    path: '/camera/filter',
    name: 'camera-filter',
    beforeEnter: (to, from, next) => {
      if (roleExist('camera-filter')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Camera - Filter", authRequired: true,
    },
    component: () => import('../views/camera/camera-filter')
  },
  {
    path: '/reports/violation',
    name: 'violation-report',
    beforeEnter: (to, from, next) => {
      if (roleExist('violation-reports')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Reports - Violation Report", authRequired: true,
    },
    component: () => import('../views/reports/violation-report')
  },
  {
    path: '/reports/alarmed-delivered',
    name: 'alarmed-delivered-report',
    beforeEnter: (to, from, next) => {
      if (roleExist('alarmed-delivered-report')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Reports - Alarmed Delivered Report", authRequired: true,
    },
    component: () => import('../views/reports/alarmed-delivered-report')
  },
  {
    path: '/reports/productivity',
    name: 'productivity-report',
    beforeEnter: (to, from, next) => {
      if (roleExist('productivity-report')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Reports - Productivity Report", authRequired: true,
    },
    component: () => import('../views/reports/productivity-report')
  },
  {
    path: '/reports/camera',
    name: 'camera-report',
    beforeEnter: (to, from, next) => {
      if (roleExist('productivity-report')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Reports - Camera Report", authRequired: true,
    },
    component: () => import('../views/reports/camera-report')
  },
  {
    path: '/reports/delivery',
    name: 'delivery-report',
    beforeEnter: (to, from, next) => {
      if (roleExist('delivery-report')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Reports - Delivery Report", authRequired: true,
    },
    component: () => import('../views/reports/delivery-report')
  },
  {
    path: '/payment/report',
    name: 'report',
    beforeEnter: (to, from, next) => {
      if (roleExist('report')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Reports - Payment Report", authRequired: true,
    },
    component: () => import('../views/payment/report')
  },
  {
    path: '/reports/lgu-violation',
    name: 'lgu-violation-report',
    beforeEnter: (to, from, next) => {
      if (roleExist('lgu-violation-report')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Reports - LGU Violation Report", authRequired: true,
    },
    component: () => import('../views/reports/lgu-violation-report')
  },
  {
    path: '/reports/double-ovr',
    name: 'double-ovr-report',
    beforeEnter: (to, from, next) => {
      if (roleExist('double-ovr-report')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Reports - Double OVR Report", authRequired: true,
    },
    component: () => import('../views/reports/double-ovr-report')
  },
  {
    path: '/reports/nov-city-completeness',
    name: 'nov-city-completeness',
    beforeEnter: (to, from, next) => {
      if (roleExist('nov-city-completeness')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Reports - NOV City Completeness", authRequired: true,
    },
    component: () => import('../views/reports/nov-city-completeness')
  },
  {
    path: '/settings/violation-types/list',
    name: 'violation-types',
    beforeEnter: (to, from, next) => {
      if (roleExist('violation-type')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Settings - Violation Types", authRequired: true,
    },
    component: () => import('../views/settings/violation-types/list')
  },
  {
    path: '/settings/violation-types/create',
    name: 'violation-type-create',
    beforeEnter: (to, from, next) => {
      if (roleExist('violation-type')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Settings - Add New Violation Type", authRequired: true,
    },
    component: () => import('../views/settings/violation-types/create')
  },
  {
    path: '/settings/violation-types/:id/edit',
    name: 'violation-type-edit',
    beforeEnter: (to, from, next) => {
      if (roleExist('violation-type')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Settings - Edit Violation Type", authRequired: true,
    },
    component: () => import('../views/settings/violation-types/_id/edit')
  },
  {
    path: '/settings/mv-type/list',
    name: 'mv-type-list',
    beforeEnter: (to, from, next) => {
      if (roleExist('mv-type')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Settings - MV Types", authRequired: true,
    },
    component: () => import('../views/settings/mv-type/list')
  },
  {
    path: '/settings/mv-type/create',
    name: 'mv-type-create',
    beforeEnter: (to, from, next) => {
      if (roleExist('mv-type')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Settings - Create MV Type", authRequired: true,
    },
    component: () => import('../views/settings/mv-type/create')
  },
  {
    path: '/settings/mv-registration-type/list',
    name: 'mv-registration-type-list',
    beforeEnter: (to, from, next) => {
      if (roleExist('mv-registration-type')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Settings - MV Registration Types", authRequired: true,
    },
    component: () => import('../views/settings/mv-registration-type/list')
  },
  {
    path: '/settings/mv-registration-type/create',
    name: 'mv-registration-type-create',
    beforeEnter: (to, from, next) => {
      if (roleExist('mv-registration-type')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Settings - Create MV Registration Type", authRequired: true,
    },
    component: () => import('../views/settings/mv-registration-type/create')
  },
  {
    path: '/settings/lgu/list',
    name: 'lgu-list',
    beforeEnter: (to, from, next) => {
      if (roleExist('lgu')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Settings - LGU", authRequired: true,
    },
    component: () => import('../views/settings/lgu/list')
  },
  {
    path: '/settings/lgu/create',
    name: 'lgu-create',
    beforeEnter: (to, from, next) => {
      if (roleExist('lgu')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Settings - Add New LGU", authRequired: true,
    },
    component: () => import('../views/settings/lgu/create')
  },
  {
    path: '/settings/lgu/:id/view',
    name: 'lgu-view',
    beforeEnter: (to, from, next) => {
      if (roleExist('lgu')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Settings - View LGU", authRequired: true,
    },
    component: () => import('../views/settings/lgu/_id/view')
  },
  {
    path: '/settings/lgu/:id/edit',
    name: 'lgu-edit',
    beforeEnter: (to, from, next) => {
      if (roleExist('lgu')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Settings - Edit LGU", authRequired: true,
    },
    component: () => import('../views/settings/lgu/_id/edit')
  },
  {
    path: '/settings/nov-templates',
    name: 'nov-templates',
    beforeEnter: (to, from, next) => {
      if (roleExist('nov-template')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Settings - NOV Templates", authRequired: true,
    },
    component: () => import('../views/settings/nov-templates')
  },
  {
    path: '/settings/return-card',
    name: 'return-card',
    beforeEnter: (to, from, next) => {
      if (roleExist('return-card')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Settings - Return Card", authRequired: true,
    },
    component: () => import('../views/settings/return-card')
  },
  {
    path: '/settings/reject-reasons/list',
    name: 'reject-reasons-list',
    beforeEnter: (to, from, next) => {
      if (roleExist('reject-reasons')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Settings - Reject Reasons", authRequired: true,
    },
    component: () => import('../views/settings/reject-reasons/list')
  },
  {
    path: '/settings/reject-reasons/create',
    name: 'reject-reasons-create',
    beforeEnter: (to, from, next) => {
      if (roleExist('reject-reasons')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Settings - Create Reject Reason", authRequired: true,
    },
    component: () => import('../views/settings/reject-reasons/create')
  },
  {
    path: '/settings/public-access',
    name: 'public-access',
    beforeEnter: (to, from, next) => {
      if (roleExist('public-access')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Settings - Public Access", authRequired: true,
    },
    component: () => import('../views/settings/public-access')
  },
  {
    path: '/settings/camera',
    name: 'camera',
    beforeEnter: (to, from, next) => {
      if (roleExist('camera')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Settings - Camera", authRequired: true,
    },
    component: () => import('../views/settings/camera')
  },
  {
    path: '/settings/whitelist',
    name: 'whitelist',
    beforeEnter: (to, from, next) => {
      if (roleExist('whitelist')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Settings - Global Whitelist", authRequired: true,
    },
    component: () => import('../views/settings/whitelist/list')
  },
  {
    path: '/settings/whitelist/create',
    name: 'whitelist-create',
    beforeEnter: (to, from, next) => {
      if (roleExist('whitelist')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Settings - Create Global Whitelist", authRequired: true,
    },
    component: () => import('../views/settings/whitelist/create')
  },
  {
    path: '/settings/whitelist-lgu',
    name: 'whitelist-lgu',
    beforeEnter: (to, from, next) => {
      if (roleExist('whitelist')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Settings - LGU Whitelist", authRequired: true,
    },
    component: () => import('../views/settings/whitelist-lgu/list')
  },
  {
    path: '/settings/whitelist-lgu/create',
    name: 'whitelist-lgu-create',
    beforeEnter: (to, from, next) => {
      if (roleExist('whitelist')) {
        next();
      }
      next({ name: 'default' })
    },
    meta: {
      title: "Settings - Create LGU Whitelist", authRequired: true,
    },
    component: () => import('../views/settings/whitelist-lgu/create')
  },
  {
    path: '/user/profile',
    name: 'user-profile',
    meta: {
      title: "User - My Profile", authRequired: true,
    },
    component: () => import('../views/account/profile')
  },
];
