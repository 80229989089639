import axios from 'axios';
import { authHeader } from './auth-header';

export const roleService = {
    getAll,
    getAllDropdown,
    create,
    update,
    destroy,
    updatePermissions
};

function getAllDropdown() {
    return axios.get('/roles', {
        params: {
            pageSize: 100
        },
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function getAll({ 
    pageSize, 
    page, 
    search, 
    orderBy, 
    sortColumn  
}) {
    return axios.get('/roles', {
        params: {
            pageSize,
            page,
            search,
            orderBy,
            sortColumn
        },
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function create(data) {
    return axios.post('/roles', data, {
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function update(data) {
    return axios.patch(`/roles/${data.id}`, data, {
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function updatePermissions(data) {
    return axios.patch(`roles/${data.id}/permissions`, {
        permissions: data.permissions
    }, {
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function destroy(id) {
    return axios.delete(`/roles/${id}`, {
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}