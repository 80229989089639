import axios from 'axios';
import { authHeader } from './auth-header';

export const mvRegistrationTypeService = {
    getAll,
    getAllDropdown,
    create,
    update,
    destroy
};

function getAll({ 
    pageSize, 
    page, 
    search, 
    orderBy, 
    sortColumn  
}) {
    return axios.get('/mvregtype', {
        params: {
            pageSize,
            page,
            search,
            orderBy,
            sortColumn
        },
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function getAllDropdown() {
    return axios.get('/mvregtype', {
        params: {
            pageSize: 1000
        },
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function create(data) {
    return axios.post('/mvregtype', data, {
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function update(data) {
    return axios.put(`/mvregtype/${data.id}`, data, {
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function destroy(id) {
    return axios.delete(`/mvregtype/${id}`, {
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}