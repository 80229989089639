import { mvRegistrationTypeService } from '../../helpers/authservice/mv-registration-type.service';
import router from '../../router/index'
import Swal from 'sweetalert2';

export const state = {
    mvRegistrationTypes: [],
    dropdownMVRegistrationTypes: [],
    pagination: {
        per_page: '',
        total: '',
        page: ''
    },
}

export const mutations = {
    FETCH_MV_REGISTRATION_TYPES: (state, payload) => {
        state.mvRegistrationTypes = payload;
    },
    FETCH_DROPDOWN_MV_REGISTRATION_TYPES: (state, payload) => {
        state.dropdownMVRegistrationTypes = payload;
    },
    FETCH_PAGINATION: (state, payload) => {
        state.pagination = payload;
    }
}

export const actions = {
    getMVRegistrationTypes: ({ commit, dispatch }, pagination) => {
        dispatch('globals/loading', null, { root: true })

        mvRegistrationTypeService.getAll(pagination)
        .then(({ data, meta }) => {
            commit('FETCH_MV_REGISTRATION_TYPES', data);
            commit('FETCH_PAGINATION', {
                page: meta.current_page,
                total: meta.total,
                per_page: meta.per_page
            });
            dispatch('globals/loaded', null, { root: true })
        })
        .catch((error) => {
            const { message } = error.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true })
        })
    },
    getDropdownMVRegistrationTypes: ({ commit, dispatch }) => {
        dispatch('globals/loading', null, { root: true })

        mvRegistrationTypeService.getAllDropdown()
        .then(({ data }) => {
            commit('FETCH_DROPDOWN_MV_REGISTRATION_TYPES', data);
            dispatch('globals/loaded', null, { root: true })
        })
        .catch((error) => {
            const { message } = error.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true })
        })
    },
    createMVRegistrationType: ({ dispatch }, body) => {
        dispatch('globals/loading', null, { root: true })

        mvRegistrationTypeService.create(body)
        .then(() => {
            dispatch('notification/clear', null, { root: true });
            router.push('/settings/mv-registration-type/list');
            Swal.fire("Success", `You have created a new MV registration type ${body.name}` , "success");
            dispatch('globals/loaded', null, { root: true })
        })
        .catch((error) => {
            const { errors } = error.response.data;
            window.scrollTo(0, 0);
            dispatch('notification/errors', errors, { root: true });
            dispatch('globals/loaded', null, { root: true })
         })
    },
    updateMVRegistrationType: ({ state, dispatch }, body) => {
        dispatch('globals/loading', null, { root: true })

        mvRegistrationTypeService.update(body)
        .then(() => {
            window.scrollTo(0, 0);
            dispatch('notification/success', 'MV registration type updated successfully', { root: true });
            dispatch('globals/loaded', null, { root: true });
            dispatch('getMVRegistrationTypes', state.pagination);
        })
        .catch((error) => {
           const { errors } = error.response.data;
           window.scrollTo(0, 0);
           dispatch('notification/errors', errors, { root: true });
           dispatch('globals/loaded', null, { root: true })
        })
    },
    deleteMVRegistrationType: ({ state, dispatch }, { id, name }) => {
        dispatch('globals/loading', null, { root: true })

        mvRegistrationTypeService.destroy(id)
        .then(() => {
            dispatch('notification/clear', null, { root: true });
            router.push('/settings/mv-registration-type/list');
            dispatch('globals/loaded', null, { root: true })
            Swal.fire("Deleted!", `MV Registration type ${name} has been successfully deleted!`, "success");
            dispatch('getMVRegistrationTypes', state.pagination);
        })
        .catch(() => {
            window.scrollTo(0, 0);
            dispatch('notification/error', `You're not allowed to delete the item "${name}" because it's been already connected to Whitelist!`, { root: true });
            Swal.fire({
                title: "Oops...",
                text: `You're not allowed to delete this item "${name}" because it's been already connected to Whitelist!`,
                icon: "error",
                confirmButtonClass: "btn btn-primary w-xs mt-2",
                buttonsStyling: false,
                showCloseButton: true,
              });
            dispatch('globals/loaded', null, { root: true })
         })
    }
}