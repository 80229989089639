export const state = {
    loading: false
}

export const mutations = {
    LOADING: (state) => {
        state.loading = true;
    },
    LOADED: (state) => {
        state.loading = false;
    }
}

export const actions = {
    loading({ commit }) {
        commit('LOADING');
    },
    loaded({ commit }) {
        commit('LOADED');
    }
}
