import axios from 'axios';
import { authHeader } from './auth-header';

export const lguService = {
    getAll,
    getDropdown,
    create,
    update,
    destroy
};

function getDropdown() {
    return axios.get('/lgu', {
        params: {
            pageSize: 100,
            page: 1
        },
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}


function getAll({ 
    pageSize, 
    page, 
    search, 
    orderBy, 
    sortColumn  
}) {
    return axios.get('/lgu', {
        params: {
            pageSize,
            page,
            search,
            orderBy,
            sortColumn
        },
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function create(data) {
    return axios.post('/lgu', data, {
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function update(data) {
    return axios.put(`/lgu/${data.id}`, data, {
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function destroy(id) {
    return axios.delete(`/lgu/${id}`, {
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}