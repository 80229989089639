import { courierService } from '../../helpers/authservice/courier.service';
import router from '../../router/index'
import Swal from 'sweetalert2';
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({   
  position: 'top'
});

export const state = {
    success: false,
    trackingLogs: [],
    couriers: [],
    dropdownCouriers: [],
    pagination: {
        per_page: '',
        total: '',
        page: ''
    },
}

export const mutations = {
    FETCH_TRACKING_LOGS: (state, payload) => {
        state.trackingLogs = payload;
    },
    FETCH_COURIERS: (state, payload) => {
        state.couriers = payload;
    },
    FETCH_DROPDOWN_COURIERS: (state, payload) => {
        state.dropdownCouriers = payload;
    },
    FETCH_PAGINATION: (state, payload) => {
        state.pagination = payload;
    },
    SET_SUCCESS: (state, payload) => {
        state.success = payload
    }
}

export const actions = {
    getCouriersDropdown: ({ commit, dispatch }) => {
        dispatch('globals/loading', null, { root: true })

        courierService.getAllDropdown()
        .then(({ data }) => {
            dispatch('notification/clear', null, { root: true });
            commit('FETCH_DROPDOWN_COURIERS', data);
            dispatch('globals/loaded', null, { root: true })
        })
        .catch((error) => {
            dispatch('notification/clear', null, { root: true });
            const { message } = error.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true })
        })
    },
    getTrackingLogs: ({ commit, dispatch }, { limit }) => {
        dispatch('globals/loading', null, { root: true })

        courierService.getRecentTrackingLogs({ limit})
        .then(({ data }) => {
            commit('FETCH_TRACKING_LOGS', data);
            dispatch('globals/loaded', null, { root: true })
        })
        .catch((error) => {
            const { message } = error.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true })
        })
    },
    getCouriers: ({ commit, dispatch }, pagination) => {
        dispatch('globals/loading', null, { root: true })

        courierService.getAll(pagination)
        .then(({ data, meta }) => {
            commit('FETCH_COURIERS', data);
            commit('FETCH_PAGINATION', {
                page: meta.current_page,
                total: meta.total,
                per_page: meta.per_page
            });
            dispatch('globals/loaded', null, { root: true })
        })
        .catch((error) => {
            const { message } = error.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true })
        })
    },
    createCourier: ({ dispatch }, body) => {
        const newCourier = {
            ...body, status: body.status ? 1 : 0
        }
        var formData = new FormData();
    
        for (const [key, value] of Object.entries(newCourier)) {
            if (value === null) {
                continue;
            }
            if (key === 'lgus') {
                newCourier.lgus.forEach((lgu) => {
                    formData.append('lgus[]', lgu.id);
                })
                continue;
            }
            formData.append(key, value);
        }

        dispatch('globals/loading', null, { root: true })
        courierService.create(formData)
        .then(() => {
            dispatch('notification/clear', null, { root: true });
            router.push('/delivery/couriers/list');
            Swal.fire("Success", `You have successfully created a new courier "${body.name}"` , "success");
            dispatch('globals/loaded', null, { root: true })
        })
        .catch((error) => {
            const { errors } = error.response.data;
            window.scrollTo(0, 0);
            dispatch('notification/errors', errors, { root: true });
            dispatch('globals/loaded', null, { root: true })
         })
    },
    updateCourier: ({ state, dispatch }, body) => {
        const existingCourier = {
            ...body, status: body.status ? 1 : 0
        }
        var formData = new FormData();
        formData.append('_method', "PUT");
    
        for (const [key, value] of Object.entries(existingCourier)) {
            if (value === null) {
                continue;
            }
            if (key === 'lgus') {
                existingCourier.lgus.forEach((lgu) => {
                    formData.append('lgus[]', lgu.id);
                })
                continue;
            }
            formData.append(key, value);
        }

        dispatch('globals/loading', null, { root: true })

        courierService.update(formData, existingCourier.id)
        .then(() => {
            window.scrollTo(0, 0);
            dispatch('notification/clear', null, { root: true });
            dispatch('notification/success', 'Courier updated successfully', { root: true });
            dispatch('globals/loaded', null, { root: true });
            dispatch('getCouriers', state.pagination);
        })
        .catch((error) => {
           const { errors } = error.response.data;
           window.scrollTo(0, 0);
           dispatch('notification/errors', errors, { root: true });
           dispatch('globals/loaded', null, { root: true })
        })
    },
    deleteCourier: ({ state, dispatch }, { id, name }) => {
        dispatch('globals/loading', null, { root: true })

        courierService.destroy(id)
        .then(() => {
            dispatch('notification/clear', null, { root: true });
            router.push('/delivery/couriers/list');
            dispatch('globals/loaded', null, { root: true })
            Swal.fire("Deleted!", `Courier "${name}" has been successfully deleted!`, "success");
            dispatch('getCouriers', state.pagination);
        })
        .catch((error) => {
            const { errors } = error.response.data;
            window.scrollTo(0, 0);
            dispatch('notification/errors', errors, { root: true });
            dispatch('globals/loaded', null, { root: true })
         })
    },
    addTracking: ({ commit, dispatch }, body) => {
        dispatch('globals/loading', null, { root: true })

        const { tracking_courier_id, notice_of_violation_number, tracking_number } = body;

        courierService.addTracking({ tracking_courier_id, notice_of_violation_number, tracking_number})
        .then(() => {
            console.log('test');
            dispatch('notification/clear', null, { root: true });
            commit('SET_SUCCESS', true);
            
            toaster.success(`Successfully submitted tracking to courier ${body.name} with NOV Number: ${body.notice_of_violation_number} and Tracking Number: ${body.tracking_number}`);
            dispatch('globals/loaded', null, { root: true })
        })
        .catch((error) => {
            dispatch('notification/clear', null, { root: true });
            commit('SET_SUCCESS', false);
            let errorsArray = [];

            const errors = error.response?.data && error.response.data?.errors;

            if (errors) {
                for (const error in errors) {
                    errorsArray = [ ...errorsArray, errors[error][0]]
                }

                for (const key in errorsArray) {
                    toaster.error(`Error: ${errorsArray[key]}`);
                }
                
                window.scrollTo(0, 0);
                dispatch('notification/errors', errors, { root: true });
                dispatch('globals/loaded', null, { root: true })
            }
         })
    },
}