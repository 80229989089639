import { authHeader } from '@/helpers/authservice/auth-header';
import axios from 'axios';

export const state = {
    type: 'success',
    isIdle: false,
    message: '',
    timeDuration: 60000 // 1 minute
};

export const mutations = {
    type(state, type) {
        state.type = type;
    },
    message(state, message) {
        state.message = message;
    },
    isIdle(state, status) {
        state.isIdle = status;
    },
    clear(state) {
        state.isIdle = false;
        state.message = null;

        clearTimeout(window.time_duration);
    },
    setIdleTime(state, time) {
        if(!time) return;
        state.timeDuration = time;
    },
};

export const actions = {
    clear({ commit }) {
        commit('clear');
    },
    fetchIdleTime({ commit }) {
        // send http request to update queue table
        return new Promise((resolve, reject) => {
            axios.get(`/queue/idle-time`,{}, {
                headers: authHeader()
            })
            .then(response => {
                commit('type', 'success');
                commit('setIdleTime', response.data.time_duration);
                resolve(response)
            })
            .catch(error => {
                commit('type', 'error');
                reject(error)
            })
        })
    },
    broadcastUserIdle({ commit }, data) {
        // send http request to update queue table
        axios.put(`/queue/${data.user_id}/phase/${data.phase_id}/set-to-idle`,{}, {
            headers: authHeader()
        })
        .then(function () {
            commit('type', 'success');
            commit('message', 'User is inactive.');
            commit('isIdle', true);
        })
        .catch(() => {
            commit('isIdle', true);
            commit('message', 'User is inactive.');
        })
    },
    broadcastUserActivate({ commit }, data) {
        // send http request to update queue table
        return new Promise((resolve, reject) => {
            axios.put(`/queue/${data.user_id}/phase/${data.phase_id}/activate`,{}, {
                headers: authHeader()
            })
            .then(response => {
                commit('type', 'success');
                commit('message', 'User is active.');
                setTimeout(function functionName() {
                    commit('clear');
                    resolve(response)
                }, 1000);
            })
            .catch(error => {
                commit('type', 'error');
                commit('message', error.response.data.message);
                reject(error)
            })
        })
    },
    checkStatus({ commit }, data) {
        axios.put(`/queue/${data.user_id}/phase/${data.phase_id}/status`,{}, {
                headers: authHeader()
            })
            .then(() => {
                commit('isIdle', false);
            })
            .catch(() => {
                commit('isIdle', true);
                commit('message', 'User is inactive.');
            })
    },
};
