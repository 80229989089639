import { paymentService } from '../../helpers/authservice/payment.service.js';

export const state = {
    order_of_payment_search_input: '',
    order_of_payment:null,
    edit_or: null,
    edit_or_search_input: '', 
    printManifest: [],
    printManifestPagination: {
        per_page: '',
        total: '',
        page: ''
    },
}

export const getters = {}

export const mutations = {
    SET_ORDER_OF_PAYMENT_SEARCH_INPUT: (state, payload) => {
        state.order_of_payment_search_input = payload;
    },
    SET_ORDER_OF_PAYMENT: (state, payload) => {
        state.order_of_payment = payload;
    },
    SET_EDIT_OR_SEARCH_INPUT: (state, payload) => {
        state.edit_or_search_input = payload;
    },
    SET_EDIT_OR: (state, payload) => {
        state.edit_or = payload;
    },
    FETCH_PRINT_MANIFEST: (state, payload) => {
        state.printManifest = payload;
    },
    FETCH_PRINT_MANIFEST_PAGINATION: (state, payload) => {
        state.printManifestPagination = payload;
    },
}

export const actions = {
    getOrderOfPayment: async ({ commit, dispatch, state }) => {
        dispatch('globals/loading', null, { root: true });
        dispatch('notification/clear', null, { root: true });
        try {
            const { data } = await paymentService.getOrderOfPayment(state.order_of_payment_search_input)
            commit('SET_ORDER_OF_PAYMENT', data);
            dispatch('globals/loaded', null, { root: true });
        } catch (error) {
            if (error.response) {
                const { message } = error.response.data;
                dispatch('notification/error', message, { root: true });
            }
            dispatch('globals/loaded', null, { root: true });
        }
    },
    getEditOR: async ({ commit, dispatch, state }) => {
        dispatch('globals/loading', null, { root: true });
        dispatch('notification/clear', null, { root: true });
        try {
            const { data } = await paymentService.getEditOR(state.edit_or_search_input)
            commit('SET_EDIT_OR', data);
            dispatch('globals/loaded', null, { root: true });
        } catch (error) {
            if (error.response) {
                const { message } = error.response.data;
                dispatch('notification/error', message, { root: true });
            }
            dispatch('globals/loaded', null, { root: true });
        }
    },
    getAllPrintManifest: ({ commit, dispatch }, pagination) => {
        dispatch('globals/loading', null, { root: true })

        paymentService.getAllPrintManifest(pagination)
        .then(({ data, meta }) => {
            commit('FETCH_PRINT_MANIFEST', data);
            commit('FETCH_PRINT_MANIFEST_PAGINATION', {
                page: meta.current_page,
                total: meta.total,
                per_page: meta.per_page
            });
            dispatch('globals/loaded', null, { root: true })
        })
        .catch((error) => {
            const { message } = error.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true });
        })
    },
    setPrintManifestPrintStatus: ({ state, dispatch }, { batch_id }) => {
        dispatch('globals/loading', null, { root: true });

        paymentService.setPrintManifestPrintStatus({ batch_id  })
        .then(() => {
            dispatch('getAllPrintManifest', state.printManifestPagination);
            dispatch('globals/loaded', null, { root: true });
        })
        .catch((err) => {
            console.log(err)
            const { message } = err.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true });
        })
    },
}
