import axios from 'axios';
import { authHeader } from './auth-header';

export const trackingService = {
    searchBatchNumber,
    updateTrackingEntries,
    scanBarcodeTrackingEntries,
    scanBarcodeDeliveryInfo,
    getDropdownTrackingStatuses,
    getDropdownTrackingStatusReasons,
    updateDeliveryInfo
};

function searchBatchNumber({ 
    batch_number, 
    date
}) {
    return axios.get('/tracking/scan/batch/search', {
        params: {
            batch_number,
            date
        },
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}


function updateTrackingEntries(batch_number) {
    return axios.post('/tracking/scan/batch/update-tracking-entries', null, {
        params: {
            batch_number
        },
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function scanBarcodeTrackingEntries({ 
    tracking_number, 
    status
}) {
    return axios.post('/tracking/scan', null, {
        params: {
            tracking_number,
            status
        },
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function scanBarcodeDeliveryInfo({ 
    tracking_number
}) {
    return axios.get('/tracking/status/scan', {
        params: {
            tracking_number
        },
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function getDropdownTrackingStatuses() {
    return axios.get('/tracking_status_type', {
        params: {
            pageSize: 1000,
            page: 1
        },
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function getDropdownTrackingStatusReasons() {
    return axios.get('/tracking_status_type_reason', {
        params: {
            pageSize: 1000,
            page: 1
        },
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}

function updateDeliveryInfo({ tracking_number, status, receiver, reason_id, date_delivered_or_returned, remarks}) {
    return axios.post(`/tracking/status/delivery-info/update`, null, {
        params: {
            tracking_number,
            status,
            receiver,
            reason_id,
            date_delivered_or_returned,
            remarks
        },
        headers: authHeader()
    })
    .then(({ data }) => {
        return data;
    })
}
