import { ovrService } from '../../helpers/authservice/ovr.service';

export const state = {
    viewBatches: [],
    viewBatchesPagination: {
        per_page: '',
        total: '',
        page: ''
    },
    ovrDotMatrix: [],
    ovrDotMatrixPagination: {
        per_page: '',
        total: '',
        page: ''
    }
}

export const mutations = {
    FETCH_VIEW_BATCHES: (state, payload) => {
        state.viewBatches = payload;
    },
    FETCH_VIEW_BATCHES_PAGINATION: (state, payload) => {
        state.viewBatchesPagination = payload;
    },
    FETCH_OVR_DOT_MATRIX: (state, payload) => {
        state.ovrDotMatrix = payload;
    },
    FETCH_OVR_DOT_MATRIX_PAGINATION: (state, payload) => {
        state.ovrDotMatrixPagination = payload;
    }
}

export const actions = {
    getAllViewBatches: ({ commit, dispatch }, pagination) => {
        dispatch('globals/loading', null, { root: true })

        ovrService.getAllViewBatches(pagination)
        .then(({ data, meta }) => {
            commit('FETCH_VIEW_BATCHES', data);
            commit('FETCH_VIEW_BATCHES_PAGINATION', {
                page: meta.current_page,
                total: meta.total,
                per_page: meta.per_page
            });
            dispatch('globals/loaded', null, { root: true })
        })
        .catch((error) => {
            const { message } = error.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true })
        })
    },
    getOVRDotMatrix: ({ commit, dispatch }, pagination) => {
        dispatch('globals/loading', null, { root: true })

        ovrService.getOVRDotMatrix(pagination)
        .then(({ data, meta }) => {
            commit('FETCH_OVR_DOT_MATRIX', data);
            commit('FETCH_OVR_DOT_MATRIX_PAGINATION', {
                page: meta.current_page,
                total: meta.total,
                per_page: meta.per_page
            });
            dispatch('globals/loaded', null, { root: true })
        })
        .catch((error) => {
            const { message } = error.response.data;
            dispatch('notification/error', message, { root: true });
            dispatch('globals/loaded', null, { root: true })
        })
    }
}